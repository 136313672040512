export default {
  namespaced: true,
  state: {
    cancelTokens: [],
  },
  getters: {
    cancelTokens: state => state.cancelTokens,
  },
  mutations: {
    addCancelToken(state, payload) {
      state.cancelTokens.push({
        token: payload.token,
        url: payload.url,
      })
    },
    clearCancelTokens(state) {
      state.cancelTokens = []
    },
    removeCancelTokens(state, payload) {
      if (payload in state.cancelTokens) {
        state.cancelTokens.splice(payload, 1)
      }
    },
  },
  actions: {
    cancelTokensPending({ state, commit }) {
      state.cancelTokens.forEach((request, index) => {
        if ('token' in request) {
          if (request.token.cancel) {
            request.token.cancel()
            commit('removeCancelTokens', index)
          }
        }
      })
    },
    cancelTokensPendingByUrl({ state, commit }, payload) {
      state.cancelTokens.forEach((request, index) => {
        if (('token' in request) && ('url' in request)) {
          if (request.url.includes(payload)) {
            if (request.token.cancel) {
              request.token.cancel()
              commit('removeCancelTokens', index)
            }
          }
        }
      })
    },
  },
}

import store from '@/store'
import { computed } from '@vue/composition-api'
import { symbolMoney } from '@core-custom/utils/money'

export const configSettingsScroll = computed(() => {
  if (store.state.capacitor.isNative === true) {
    return {
      handlers: ['touch'],
    }
  }

  return {
    handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch'],
  }
})

export const currentBreakPointMobile = computed(() => {
  if ((store.getters['capacitor/orientationDevice'] === 'portrait') && (store.getters['app/currentBreakPoint'] === 'xs')) {
    return true
  }
  if ((store.getters['capacitor/orientationDevice'] === 'landscape') && (['xs', 'md', 'sm'].includes(store.getters['app/currentBreakPoint']))) {
    return true
  }

  return false
})

export const formatPrice = value => {
  if ((value !== '') && (value !== undefined) && (value !== null) && (value !== 0)) {
    let showMoney = false

    if (value.indexOf(symbolMoney) > -1) {
      showMoney = true
    }

    let sValue = value
    sValue = sValue.replaceAll(symbolMoney, '')
    sValue = sValue.trim()

    const aValue = sValue.split(',')
    const [valueFormat, valueDecimal] = aValue

    if ((valueDecimal !== undefined) && (valueDecimal !== null) && (valueDecimal === '00')) {
      if (showMoney === true) {
        return `${valueFormat} ${symbolMoney}`
      }

      return valueFormat
    }

    if (showMoney === true) {
      return `${sValue} ${symbolMoney}`
    }

    return sValue
  }
  if (value === 0) {
    return 0
  }

  return ''
}

export const toCurrency = value => {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
  return formatter.format(value).replace(/\s/g, '')
}

export const _ = null

const routeGrowthPowerBI = () => import(/* webpackChunkName: "growth-powerbi" */ '@/views/modules/growth/powerbi/index.vue')
const routeGrowthReportPowerBI = () => import(/* webpackChunkName: "growth-powerbi-report" */ '@/views/modules/growth/powerbi/report.vue')

export default [
  {
    path: '/powerbi/reportsBI',
    name: 'growth-powerbi',
    component: routeGrowthPowerBI,
    meta: {
      resource: 'growth_powerbi',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application email-application-full-width',
      breadcrumbNavbar: [
        {
          text: 'modules.growth.Crescimento', /* <i18n path="modules.growth.Crescimento"></i18n> */
        },
        {
          text: 'modules.growth.Power BI', /* <i18n path="modules.growth.Power BI"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/powerbi/reportsBI/:typeReport',
    name: 'growth-powerbi-report',
    component: routeGrowthReportPowerBI,
    meta: {
      resource: 'growth_powerbi',
      action: 'list',
      contentClass: 'email-application email-application-full-width',
      breadcrumbNavbar: [
        {
          text: 'modules.growth.Crescimento', /* <i18n path="modules.growth.Crescimento"></i18n> */
        },
        {
          text: 'modules.growth.Power BI', /* <i18n path="modules.growth.Power BI"></i18n> */
        },
        {
          text: 'modules.growth.Report', /* <i18n path="modules.growth.Report"></i18n> */
          active: true,
        },
      ],
    },
  },
]

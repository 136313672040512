import Vue from 'vue'
import ModalCheckInfo from '@/views/modules/crm/leads/form/form_data/modal/modalCheckInfo.vue'
import
{
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  parsePhoneNumber,
} from 'libphonenumber-js/max'

export const mixFormGeral = {
  computed:
    {
      numberPhoneValidate() {
        try {
          if (this.form_data.sw453s06) {
            return this.$refs.sw453s06.phoneObject
          }
        } catch (err) {
          //
        }

        return null
      },
    },
  methods: {
    onDropdownOpenTelInput() {
      this.$nextTick(() => {
        const widthElInputTel = this.$refs.sw453s06.$el.offsetWidth
        const el = this.$refs.sw453s06.$refs.list
        el.querySelector('.vti__search_box').placeholder = this.$t('Digite o nome')
        el.querySelector('.vti__search_box').addEventListener('keydown', evt => {
          const charCode = (evt.which) ? evt.which : window.event.keyCode

          if (charCode === 187) {
            evt.preventDefault()
          }
        })

        el.style.width = `${widthElInputTel}px`
      })
    },
    inputValidateNumberPhone(obj) {
      if (obj?.phoneObject?.number !== undefined && obj.phoneObject.number !== '') {
        try {
          const phoneNumber = parsePhoneNumber(obj.phoneObject.number, obj.phoneObject?.countryCode || '')
          if (phoneNumber) {
            const sIsValidPhoneNumber = phoneNumber.isValid()
            if (sIsValidPhoneNumber === false) {
              this.$nextTick(() => {
                this.$store.dispatch('sw453/updateFieldValue', { field: 'sw453s06', value: '' })
              })
            } else {
              this.$nextTick(() => {
                this.$store.dispatch('sw453/updateFieldValue', {
                  field: 'sw453s06',
                  value: phoneNumber.formatInternational(),
                })
              })
            }
          }
        } catch (error) {
          //
        }
      } else {
        this.$nextTick(() => {
          this.$store.dispatch('sw453/updateFieldValue', { field: 'sw453s06', value: '' })
        })
      }
    },
    checkValidateNumberPhone($event, phoneObject) {
      this.configPhone.inputOptions.maxlength = 25
      try {
        const phoneNumber = parsePhoneNumber($event, phoneObject?.countryCode || '')
        if (phoneNumber) {
          const sIsPossiblePhoneNumber = isPossiblePhoneNumber(`${$event}0`) // Insert +1 character to simulate
          const sValidatePhoneNumberLength = validatePhoneNumberLength(`${$event}0`) // Insert +1 character to simulate

          if (sValidatePhoneNumberLength === 'TOO_LONG') {
            if (sIsPossiblePhoneNumber === false) {
              this.configPhone.inputOptions.maxlength = $event.length ?? 0
            }
          }
        }
      } catch (error) {
        //
      }
    },
    getTxt(value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          let txt = ''

          value.forEach(o => {
            if ((o !== null) && (o !== undefined)) {
              txt += `${o}, `
            }
          })

          return txt.slice(0, -2) || '-----'
        }
        return '-----'
      }

      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
    async showMsgErrorRequestForm(error, errorFielsRequired) {
      let messageAlertError = ''

      if (error instanceof Error) {
        if (error.message !== '') {
          if (errorFielsRequired === true) {
            messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          } else {
            messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$t('Alerta')}</h3>`
          }

          messageAlertError += `<p class="mb-1">${error.message}</p>`
        }
      } else if (Object.keys(error).length > 0) {
        if ((Object.keys(error).length === 1) && ('message' in error) && (error.message === '')) {
          messageAlertError = ''
        } else {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          Object.keys(error).forEach(key => {
            if (error[key].length > 0) {
              error[key].forEach(txt => {
                messageAlertError += `<p class="mb-1">${txt}</p>`
              })
            }
          })
        }
      }

      if (messageAlertError !== '') {
        this.$swal({
          title: '',
          html: messageAlertError,
          icon: '',
          width: 600,
          confirmButtonText: this.$t('Fechar'),
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
            htmlContainer: 'text-left',
            content: 'pl-0 pr-0 pl-sm-2 pr-sm-2',
          },
          buttonsStyling: false,
        })
      }
    },
    async showMsgDetailRequest(pIcon, pDetail, resolve) {
      this.$swal({
        title: '',
        icon: pIcon || '',
        html: '<div id="modal-check-info" />',
        showCloseButton: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Continuar com a duplicação'),
        cancelButtonText: this.$t('Voltar à edição'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
        willOpen: () => {
          const ComponentClass = Vue.extend(ModalCheckInfo)
          this.vueModalCheckInfoInstance = new ComponentClass({
            propsData: {
              itens: pDetail,
            },
          })
          this.vueModalCheckInfoInstance.$mount()
          document.getElementById('modal-check-info').appendChild(this.vueModalCheckInfoInstance.$el)
        },
      }).then(result => {
        this.vueModalCheckInfoInstance.$destroy()
        this.vueModalCheckInfoInstance = null

        if (result.value === true) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
  },
  filters: {
    lowerBold(value) {
      if (!value) return ''
      return `<b>${value.toLowerCase()}</b>`
    },
  },
}

export const _ = null

export const configMoney = {
  locale: 'pt',
  currency: { prefix: '', suffix: '' },
  valueAsInteger: true,
  distractionFree: false,
  precision: 2,
  autoDecimalMode: true,
  valueRange: { min: 0 },
  allowNegative: false,
}

export const symbolMoney = '€'

export const _ = null

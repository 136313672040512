<template>
  <div
    class="d-flex justify-content-between align-items-center"
    :class="[extraClass, {'cursor-pointer': showLinkDetail }]"
    @click.stop.prevent="openViewListing(listing)"
  >
    <b-media
      no-body
      class="flex-container"
    >
      <b-media-aside
        v-if="listing.image!==''"
        class="mr-75"
      >
        <b-avatar
          rounded
          size="60"
          :src="listing.image + '?v=' + new Date().getTime()"
          variant=""
        />
      </b-media-aside>
      <b-media-aside
        v-else
        class="mr-75"
      >
        <b-avatar
          rounded
          size="60"
          :src="require('@/assets/images/zome/listingNotFound300.png')"
          variant=""
        />
      </b-media-aside>

      <b-media-body class="my-auto flex-container">
        <p class="text-truncate m-0 fontSmall">
          <template v-if="listing.pid">
            {{ listing.pid }}
          </template>
          <template v-else>
            {{ '\xa0' }}
          </template>
        </p>
        <h6 class="text-truncate mb-0">
          {{ listing.tipologia || '' }}
        </h6>
        <p class="text-truncate m-0 fontSmall">
          <template v-if="listing.morada">
            {{ listing.morada }}
          </template>
          <template v-else>
            {{ $t('Sem localização definida') }}
          </template>
        </p>
      </b-media-body>
    </b-media>
    <div class="d-flex align-items-center ml-2">
      <div
        class="font-weight-bolder text-nowrap"
        :class="{
          'text-primary': ((listing.percPriceDrop !== '') ? false : true),
          'text-danger': ((listing.percPriceDrop !== '') ? true : false)
        }"
      >
        <template v-if="listing.price">
          {{ formatPrice(listing.price) }}
          <div
            v-if="listing.percPriceDrop"
            class="text-right"
          >
            {{ listing.percPriceDrop }}
          </div>
        </template>
        <template v-else>
          {{ '\xa0' }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },
  props: {
    listing: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    extraClass: {
      type: Array,
      default: () => [],
    },
    showLinkDetail: {
      type: Boolean,
      default: false,
      required: false,
    },
    openViewListing: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
}
</script>

<style lang="scss" scoped>
.flex-container {
  flex: 1;
  min-width: 0;
}

.fontSmall{
 font-size: $small-font-size;
}
</style>

<template>
  <div>

    <b-modal
      id="modalImportLeads"
      ref="modalImportLeads"
      size="lg"
      cancel-variant=""
      ok-title=""
      cancel-title=""
      centered
      :hide-footer="true"
      :close-on-backdrop="false"
      :close-on-esc="false"
      no-close-on-backdrop
      hide-header-close
      :visible="true"
      @backdrop="handleBackdrop"
      @hide="handleBackdrop"
      @ok="handleBackdrop"
      @cancel="handleBackdrop"
    >
      <template slot="modal-title">
        {{ $t('Novos Leads desde a última sessão na ZAP') }}
        <b-badge
          class="ml-1"
          variant="danger"
        >
          {{ totalLeads }}
        </b-badge>
      </template>

      <vue-perfect-scrollbar
        class="b-modal-scrollarea-form"
        :settings="configSettingsScroll"
      >
        <b-container
          class="pt-2 pb-0 pl-2 pr-2"
          fluid
        >
          <list-data
            v-if="showListLeads === true"
            :listview="listviewData"
            :data="data"
            :txt="txt"
            :view-lead="viewLead"
            :close-modal="closeModal"
          />

          <info-lead
            v-if="showInfoLead === true"
            ref="infoLeadForm"
            :update-lead="updateLead"
            :open-view-listing="openViewListing"
            :create-new-seguimento="createNewSeguimento"
            :view-seguimento="viewSeguimento"
            :create-new-note="createNewNote"
            :open-view-market-research="openViewMarketResearch"
          />
        </b-container>
      </vue-perfect-scrollbar>

      <div
        v-if="showInfoLead === true"
        class="d-flex p-1 justify-content-center"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="primary"
          class="mr-1 sw-button-action"
          @click.stop.prevent="saveForm"
        >
          {{ $t('Guardar') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="sw-button-action"
          @click.stop.prevent="viewListLeads"
        >
          {{ $t('Voltar') }}
        </b-button>

      </div>

      <component
        :is="ModalQuickViewListing"
        v-if="showModalQuickView === true"
        ref="modalQuickViewListing"
        :id-listing="idListing"
        :show-more-options="false"
        :close-modal="closeModalQuickView"
      />

      <form-create-new-seguimento
        v-if="showModalNewSeguimento===true"
        ref="modalFormSeguimento"
        :key-reg-lead-import="idLead"
        :close-modal-new-seguimento="closeModalNewSeguimento"
      />

      <view-seguimento
        v-if="showInfoSeguimento===true"
        ref="modalInfoSeguimento"
        :row-data="infoSeguimento"
        :create-new="createNewSeguimento"
        :close-modal-view-seguimento="closeModalViewSeguimento"
        :show-notes="false"
      />

      <form-create-new-note
        v-if="showModalNewNote===true"
        ref="modalFormNote"
        :data="infoNote"
        :close-modal="closeModalNote"
        :key-reg-lead-import="idLead"
      />

    </b-modal>

  </div>
</template>

<script>
import store from '@/store'
import {
  BModal, VBModal, BContainer, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showMsgRequest } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import modelImportLeads from '@store-modules/crm/leads/sw453/index'
import crmLeadsFormModule from '@store-modules/crm/leads/form'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import { onUnmounted } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import checkNested from 'check-nested'
import useCapacitor from '@core-custom/utils/capacitor'
import ListData from './listData.vue'
import InfoLead from './infoLead.vue'
import { mixFormGeral } from './mixins'

export default {
  components: {
    BModal,
    BContainer,
    BButton,
    BBadge,
    VuePerfectScrollbar,
    ListData,
    InfoLead,

    FormCreateNewSeguimento: () => import(/* webpackChunkName: "leads-import-new-seguimento-form" */ /* webpackPrefetch: true */ '@/views/modules/crm/leads/form/form_data/seguimentos/form.vue'),
    ViewSeguimento: () => import(/* webpackChunkName: "leads-import-new-seguimento-view" */ /* webpackPrefetch: true */ '@/views/modules/crm/leads/form/form_data/seguimentos/view.vue'),
    FormCreateNewNote: () => import(/* webpackChunkName: "leads-import-new-note-form" */ /* webpackPrefetch: true */ '@/views/modules/crm/leads/form/form_data/sw044/form.vue'),
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [showMsgRequest, mixFormGeral],
  props: {},
  data() {
    return {
      showListLeads: true,
      showInfoLead: false,
      showModalQuickView: false,
      showModalNewSeguimento: false,
      showInfoSeguimento: false,
      showModalNewNote: false,
      idListing: '',
      idLead: '',
      infoSeguimento: {},
      infoNote: {},
    }
  },
  computed: {
    ...mapGetters('sw453', ['listviewData', 'data', 'txt', 'form_data', 'totalLeads']),
  },
  mounted() {
    this.$root.$emit('app::loading', false)
  },
  methods: {
    async showModal() {
      await this.$refs.modalImportLeads.show()
    },
    async closeModal() {
      await this.$refs.modalImportLeads.hide()
    },
    // handleBackdrop(ev) {
    //   ev.preventDefault()
    // },
    handleBackdrop() {
    },
    async viewLead(id, bCheckButtons) {
      await this.$store.dispatch('sw453/getInfoLead', {
        sw453s01: id,
        checkButtons: bCheckButtons,
      }).then(res => {
        if (res === true) {
          this.showListLeads = false
          this.showInfoLead = true
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    viewListLeads() {
      this.showInfoLead = false
      this.showListLeads = true
    },
    openViewListing(row) {
      this.idListing = btoa(row.id)
      this.showModalQuickView = true
    },
    closeModalQuickView() {
      this.idListing = ''
      this.showModalQuickView = false
    },
    updateLead(idLead, vlAction) {
      let txtTitle = ''
      if (vlAction === 'skip') {
        txtTitle = `${this.$t('Deseja tratar esta Lead mais tarde?')}`
      } else {
        txtTitle = `${this.$t('Deseja eliminar a Lead? Esta ação é irreversível')}`
      }

      this.$swal({
        title: '',
        html: txtTitle,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$root.$emit('app::loading', true)
          this.$store.dispatch('sw453/updateLead', { sw453s01: idLead, action: vlAction }).then(async resSave => {
            this.$root.$emit('app::loading', false)
            if (resSave.error !== 1) {
              await this.showMsgSuccessRequest({ message: resSave.msg })
              this.showListLeads = true
              this.showInfoLead = false
            } else {
              await this.showMsgErrorRequest({ message: resSave.msg })
              if (resSave.remove === 1) {
                this.showListLeads = true
                this.showInfoLead = false
              }
            }
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async saveForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true

        this.$swal({
          title: '',
          html: `${this.$t('Deseja gravar Lead?')}`,
          icon: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            self.$refs.infoLeadForm.$refs.formSaveLeadValidator.validateWithInfo()
              .then(async ({
                isValid, errors,
              }) => {
                if (isValid) {
                  await this.saveFormFinish()
                } else {
                  throw errors
                }
              })
              .catch(async error => {
                this.showMsgErrorRequestForm(error)
              })
          }
        })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async saveFormFinish() {
      // Verifica se o numero de telefone e email estao preenchidos
      let bHavePhoneEmail = false

      if (checkNested(this.form_data, 'sw453s06') && (this.form_data.sw453s06 !== '')) {
        bHavePhoneEmail = true
      }

      if (checkNested(this.form_data, 'sw453s07') && (this.form_data.sw453s07 !== '')) {
        bHavePhoneEmail = true
      }

      if (bHavePhoneEmail === false) {
        throw new Error(this.$t('O campo do {phone} ou {email} é obrigatório', {
          phone: `<b>${this.$t('telemóvel')}</b>`,
          email: `<b>${this.$t('email')}</b>`,
        }))
      }

      const vPhone = this.form_data.sw453s06 || ''
      const vEmail = this.form_data.sw453s07 || ''

      // Verifica se este registo já se encontra duplicado
      await this.$store.dispatch(`${this.NAMESPACE_SW025_FORM}/checkExistAllMyLeads`, {
        importLead: true,
        sw025s05: vPhone,
        sw025s06: vEmail,
      }).then(async res => {
        if (res.error === 1) {
          if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
            const actionModal = new Promise(resolve => {
              this.showMsgDetailRequest('warning', res.alerts, resolve)
            })

            await actionModal.then(result => {
              if (result === false) {
                throw new Error('')
              }
            })
          }
        }
      }).catch(error => {
        throw error
      })

      await this.$store.dispatch('sw453/saveLead').then(async resSave => {
        if (resSave.error === 1) {
          await this.showMsgErrorRequest({ message: resSave.msg })
        } else {
          await this.showMsgSuccessRequest({ message: resSave.msg })
        }

        if (resSave.error !== 1 || resSave.remove === 1) {
          this.showListLeads = true
          this.showInfoLead = false
        }
      }).catch(error => {
        throw error
      })
    },
    createNewSeguimento(idLead) {
      this.idLead = btoa(idLead)
      this.showModalNewSeguimento = true
    },
    closeModalNewSeguimento() {
      this.viewLead(atob(this.idLead), true)
      this.showModalNewSeguimento = false
      this.idLead = ''
    },
    viewSeguimento(idLead, infoSeguimento) {
      this.idLead = btoa(idLead)
      this.showInfoSeguimento = true
      this.infoSeguimento = infoSeguimento
    },
    closeModalViewSeguimento() {
      this.viewLead(atob(this.idLead), true)
      this.showInfoSeguimento = false
      this.infoSeguimento = {}
    },
    createNewNote(idLead, infoNote) {
      this.idLead = btoa(idLead)

      if (infoNote !== '') {
        this.infoNote = infoNote
      }

      this.showModalNewNote = true
    },
    closeModalNote() {
      this.viewLead(atob(this.idLead), true)
      this.showModalNewNote = false
      this.infoNote = {}
      this.idLead = ''
    },
    async openViewMarketResearch(idImovel) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('sw453/getMarketStudy', { id: btoa(idImovel) }).then(async url => {
        this.$root.$emit('app::loading', false)

        if (url !== '') {
          useCapacitor.openUrlNewTab(url)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const NAMESPACE_IMPORT_LEADS = 'sw453'
    const NAMESPACE_SW025_FORM = 'crm_leads_form_modalCreate'
    const MODEL_SEGUIMENTOS = 'seguimentos'

    if (!store.hasModule(NAMESPACE_IMPORT_LEADS)) {
      store.registerModule(NAMESPACE_IMPORT_LEADS, modelImportLeads)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_IMPORT_LEADS)) store.unregisterModule(NAMESPACE_IMPORT_LEADS)
      })
    }

    if (!store.hasModule(NAMESPACE_SW025_FORM)) {
      store.registerModule(NAMESPACE_SW025_FORM, crmLeadsFormModule)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SW025_FORM)) store.unregisterModule(NAMESPACE_SW025_FORM)
      })
    }

    if (!store.hasModule(MODEL_SEGUIMENTOS)) {
      store.registerModule(MODEL_SEGUIMENTOS, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SEGUIMENTOS)) store.unregisterModule(MODEL_SEGUIMENTOS)
      })
    }

    const ModalQuickViewListing = () => import(/* webpackChunkName: "modal-quick-view-listing" */ /* webpackPrefetch: true */ '@/views/modules/components/listing/quickView.vue')

    return {
      NAMESPACE_IMPORT_LEADS,
      NAMESPACE_SW025_FORM,
      configSettingsScroll,

      ModalQuickViewListing,
    }
  },
}
</script>

<style lang="scss">
#modalImportLeads .modal-dialog {
  max-width: 99% !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  margin: 0px 0px 0px 9px !important;
}

#modalImportLeads .modal-dialog .b-modal-scrollarea-form {
  height: calc(var(--vh, 1vh) * 100 - calc(10.3rem) - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)) !important;
}

#modalImportLeads .modal-dialog .ps--active-y {
  height: calc(var(--vh, 1vh) * 100 - calc(15rem) - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)) !important;
}

#modalImportLeads .modal-dialog .b-modal-scrollarea-form .vti__search_box {
  width: 100% !important;
}
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

const loadLocaleMessages = () => {
  const locales = require.context('@public/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const localesDefault = require.context('@public/localesDefault', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  const localesApp = []

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]

      messages[locale] = {}

      localesApp.push(locale)
    }
  })

  localesDefault.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const resLocale = localesDefault(key)

      messages[locale] = { ...messages[locale], ...resLocale }
    }
  })

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]

      if (process.env.VUE_APP_URL_LOCALES !== '') {
        axios.get(`${process.env.VUE_APP_URL_LOCALES}${key}?${new Date().getTime()}`).then(res => {
          if ((res !== null) && (res !== undefined) && ('data' in res)) {
            messages[locale] = { ...messages[locale], ...res.data }
          }
        })
      } else {
        const resLocale = locales(key)
        messages[locale] = { ...messages[locale], ...resLocale }
      }
    }
  })

  // Entradas de mensagens estaticas, para que nao estejam no ficheiro json para nao se traduzir
  if (localesApp.length > 0) {
    localesApp.forEach(lang => {
      messages[lang]['static.menuListingName'] = ''
      messages[lang]['static.menuListingNameHUB'] = ''
      messages[lang]['static.menuShareAgentsNameHUB'] = ''
      messages[lang]['static.menuShareListingNameHUB'] = ''
    })
  }

  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true, // Para ocultar alertas de variaveis nao traduzidas,
})

<template>
  <div>

    <b-modal
      id="modalAuthLockSreen"
      ref="modal"
      cancel-variant=""
      ok-title=""
      cancel-title=""
      centered
      :hide-footer="true"
      :close-on-backdrop="false"
      :close-on-esc="false"
      hide-header
      hide-header-close
      no-close-on-backdrop
      :visible="true"
      @backdrop="handleBackdrop"
      @hide="handleBackdrop"
      @ok="handleBackdrop"
      @cancel="handleBackdrop"
    >
      <template v-slot:modal-header-close />

      <validation-observer
        ref="lockscreenForm"
        #default="{invalid}"
      >
        <b-form
          @submit.prevent="authLockscreen"
        >

          <b-row class="text-center">
            <b-col
              md="12"
              cols="12"
              class="mb-1"
            >
              <div class="mb-1">
                <b-avatar
                  size="40"
                  :src="$store.getters['auth/currentUserSession'].image"
                />
              </div>
              <b-card-text class="font-weight-bold">
                {{ $store.getters['auth/currentUserSession'].name }}
              </b-card-text>
            </b-col>
          </b-row>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name="$t('password')"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="userPassword"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="userPassword"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid || loadingSubmit"
          >
            <b-spinner
              v-if="loadingSubmit===true"
              small
            />
            <span v-if="loadingSubmit===true">&nbsp;{{ $t('Aguarde...') }}</span>
            <span v-if="loadingSubmit===false">&nbsp;{{ $t('Entrar') }}</span>
          </b-button>

          <p class="text-center mt-2">
            <b-link @click.stop.prevent="redirectToAuth()">
              {{ $t('Entrar com outro utilizador') }}
            </b-link>
          </p>

        </b-form>
      </validation-observer>

    </b-modal>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup, BButton, BRow, BCol, BCardText, BAvatar, BInputGroup, BInputGroupAppend, BSpinner, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Geolocation } from '@capacitor/geolocation'
import btoa from 'btoa'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    resolve: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loadingSubmit: false,
      userPassword: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.$root.$emit('app::loading', false)
  },
  methods: {
    handleBackdrop(ev) {
      ev.preventDefault()
    },
    async authLockscreen() {
      this.loadingSubmit = true
      const getLocation = async () => {
        try {
          const position = await Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
            maximumAge: 3000,
            timeout: 5000,
          })

          return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
        } catch (error) {
          return null
        }
      }

      getLocation().then(location => {
        this.$refs.lockscreenForm.validate().then(success => {
          if (success) {
            let sGeoApp = null

            if (location) {
              const { latitude, longitude } = location
              if (!!latitude && !!longitude) {
                sGeoApp = `${latitude}#${longitude}`
              }
            }

            const formData = new FormData()
            formData.append('emailAuth', btoa(this.$store.getters['auth/currentUserSession'].email))
            formData.append('password', this.userPassword)
            formData.append('locationAuth', ((sGeoApp !== null) ? btoa(sGeoApp) : ''))
            formData.append('country', this.$store.getters['auth/currentUserSession'].country)

            this.$store
              .dispatch('auth/authLockscreen', formData)
              .then(response => {
                if (Object.keys(response.configMobile).length === 1) {
                  const responseAuth = response.configMobile[Object.keys(response.configMobile)[0]]

                  if ((responseAuth.error === 1) && (responseAuth.msg !== '')) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$t('Alerta'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: responseAuth.msg,
                      },
                    })
                  } else if (!!response.accessToken && !!response.refreshToken) {
                    this.$store.dispatch('auth/loginUser', response).then(() => {
                      this.resolve(response)
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$t('Alerta'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: this.$t('Não foi possível obter o token de autenticação'),
                      },
                    })
                  }
                }

                this.loadingSubmit = false
              })
              .catch(responseError => {
                if ((responseError.error === 1) && (responseError.msg !== '')) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Alerta',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: responseError.msg,
                    },
                  })
                }
                this.loadingSubmit = false
              })
          } else {
            this.loadingSubmit = false
          }
        })
      })
    },
    async redirectToAuth() {
      this.$root.$emit('app::hideModalAuthLogin')
      await this.$store
        .dispatch('auth/logout')
        .then(() => this.$router.push({ name: 'auth-login' }))
    },
  },
}
</script>

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import { Device } from '@capacitor/device'
import { SplashScreen } from '@capacitor/splash-screen'

import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import App from './App.vue'
// import './registerServiceWorker'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import do css geral com vai ser usado quase em todas as paginas
require('@core/scss/base/pages/app-email.scss')

Vue.config.productionTip = false

sync(store, router) // Ex de utilizar: this.state.route

SplashScreen.show()

const checKDeviceNative = async () => new Promise(resolve => {
  Device.getInfo().then(infoDevice => {
    if (['ios', 'android'].includes(infoDevice.operatingSystem) && !['web'].includes(infoDevice.platform)) {
      resolve(true)
    } else {
      resolve(false)
    }
  })
})

const getDeviceID = async () => new Promise(resolve => {
  Device.getId().then(res => {
    store.commit('capacitor/setDeviceID', res.uuid)
    resolve(true)
  })
})

const checKDeviceLanguage = async () => new Promise(resolve => {
  Device.getLanguageCode().then(res => {
    resolve(res.value || '')
  })
})

const initApp = async localeDevice => {
  if ((localeDevice !== null) && (localeDevice !== undefined)) {
    if (['en', 'pt', 'es'].includes(localeDevice.toLowerCase())) {
      i18n.locale = localeDevice
    } else {
      const [localeDevicePre] = localeDevice.toLowerCase().split('-')

      if (['en', 'pt', 'es'].includes(localeDevicePre)) {
        i18n.locale = localeDevicePre
      }
    }
  }

  window.oVue = new Vue({
    router,
    store,
    i18n,
    mounted() {
      //
    },
    render: h => h(App),
  }).$mount('#app')
}

const clearLocalStoragePreferencePageUser = async () => {
  const aKeyStorageDeny = ['template-app', 'template-app-custom-user', 'accessToken', 'refreshToken', 'capacitor.badge', '_capuid', 'user', 'router-default', 'push-notification-token']

  for (let i = 0; i < localStorage.length; i += 1) {
    const sNameKeyStorage = localStorage.key(i)

    if (aKeyStorageDeny.includes(sNameKeyStorage)) {
      //
    } else {
      localStorage.removeItem(sNameKeyStorage)
    }
  }
}

checKDeviceNative().then(isNative => {
  if (isNative === false) {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'block'
    }
  }

  Promise.all([store.dispatch('auth/getConfigServer')])
    .then(async () => {
      await checKDeviceLanguage().then(async lang => {
        let localeLoadLang = lang

        await getDeviceID()
        await store.dispatch('auth/getPreferenceByUserAuth').then(preference => {
          if (preference.skin !== '') {
            localStorage.setItem('template-app', preference.skin)
            localStorage.setItem('template-app-custom-user', true)
            store.commit('appConfig/UPDATE_SKIN', preference.skin)
          }

          if (preference.locale !== '') {
            localeLoadLang = preference.locale
          }
        })

        await initApp(localeLoadLang)
        await clearLocalStoragePreferencePageUser()
      })
    })
})

import { ref } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

export const defaultState = () => ({
  // Gerais
  mqShallShowLeftSidebar,
  tabContentStepShow: ref(0),
  loaded: ref(false),

  // Registo principal - sw025
  form_data: ref({}),
  form_data_sw025: {},

  // Separador - Contactos adicionais
  form_sw095: ref([]),
  form_data_sw095: [],

  // Dados base formulario
  classificacaoLead: ref([]),
  classificacaoRecrutamento: ref([]),
  tipoClienteLead: ref([]),
  estadosLeadComprador: ref([]),
  estadosLeadVendedor: ref([]),
  tiposContactosAdicionais: ref([]),
  grausParentesco: ref([]),
  classesTipologiasGeral: ref([]),
  condicaoImovel: ref([]),
  fonteContactos: ref([]),
  localizacaoAndares: ref([]),
  tiposImoveisCategoria: ref([]),
  countries: ref([]),
  typeUserCreateLead: ref(0),
  userCandSendLeads: ref(0),
  newReference: ref(0),
  userCandSendLeadsAsReference: ref(0),
  generos: ref([]),
  tiposNotificacaoDataAniversario: ref([]),
  optionsYesNo: ref([]),
  periodos: ref([]),
  nivelBlockEstadoComprador: ref(0),
  nivelBlockEstadoVendedor: ref(0),
  previewPhotos: ref({}),
  empresasCRM: ref([]),
  isBlacklist: ref(false),
  elegibilidadeContactoComercial: ref([]),
  infoConsentimento: ref({}),
  fonteContactoTiposAnuncio: ref([]),
  fonteContactoSitesImobiliarios: ref([]),
  fonteContactoHUBs: ref([]),
  accessUserCreateLeadShareTipoRule: ref(''),
  tiposSeguimentos: [],

  importedIsMobile: ref(false),
  executeActionShare: '',
  isSavingData: ref(false),
  updateRgpd: '',
  sw029s01Assoc: '',
  sw029s01Nome: '',
  permUserActionEdit: 0,
  sourceCreateLeadContact: '',

  addCampanha: 0,
  idCampanha: '',
  semanaInicio: '',
  mesInicio: '',

  messageInfo: '',

  alertsRGPD: ref([]),

  showBlockTeam: ref(false),
  teamUsersCreate: ref([]),
  teamTabsCreate: ref([]),
  teamBookmarksCreate: ref([]),
  teamNameTabSelected: ref(''),

  showFieldComprador: ref(false),
  showFieldVendedor: ref(false),

  // Variaveis dinamicas
  keyReg: ref(''),
  dynamicIDForm: ref(''),

  // Informacao estatica
  templatesContactosAdicionais: [
    {
      id: 1,
      template: 'block_contactoAdicional_telefone.vue',
      tag: 'telefone',
    },
    {
      id: 2,
      template: 'block_contactoAdicional_email.vue',
      tag: 'email',
    },
    {
      id: 3,
      template: 'block_contactoAdicional_facebook.vue',
      tag: 'facebook',
    },
    {
      id: 4,
      template: 'block_contactoAdicional_twitter.vue',
      tag: 'twitter',
    },
    {
      id: 5,
      template: 'block_contactoAdicional_linkedin.vue',
      tag: 'linkedin',
    },
    {
      id: 6,
      template: 'block_contactoAdicional_outra.vue',
      tag: 'outra',
    },
    {
      id: 7,
      template: 'block_contactoAdicional_skype.vue',
      tag: 'skype',
    },
  ],

  // Tipos de Leads
  typeLeadsIDs: {
    contact: 1,
    lead: 0,
  },

})

export const defaultStateRouter = () => ({
  paramsUrl: ref({}),
  typeAction: ref(''),
  convertContactToLead: false,
  modeFormAction: ref(''),
})

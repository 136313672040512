import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import marketModule from '@store-modules/listings/marketStudy/index'
import { Browser } from '@capacitor/browser'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'

const routeMarketIframe = () => import(/* webpackChunkName: "market-iframe-link" */ '@/views/modules/market/iframe.vue')

export default [
  {
    path: '/market',
    name: 'market',
    component: routeMarketIframe,
    meta: {
      resource: 'Geral',
      action: 'manager',
      contentRenderer: 'sidebar-left',
      contentClass: 'page-iframe',
    },
    async beforeEnter(to, from, next) {
      const MARKET_MODULE_NAME = 'market'

      if (!store.hasModule(MARKET_MODULE_NAME)) {
        store.registerModule(MARKET_MODULE_NAME, marketModule)

        onUnmounted(() => {
          if (store.hasModule(MARKET_MODULE_NAME)) store.unregisterModule(MARKET_MODULE_NAME)
        })
      }

      window.oVue.$root.$emit('app::loading', true)

      await store.dispatch('market/getMarketStudy', { general: true }).catch(error => {
        if (useCapacitor.isNative() === true) {
          Dialog.alert({
            title: this.$t('Alerta'),
            message: error.message,
          })
        } else {
          window.oVue.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: window.oVue.$t('Alerta'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        }
      })

      window.oVue.$root.$emit('app::loading', false)

      const urlMarket = store.getters['market/urlMarket']
      if (urlMarket !== undefined && urlMarket !== '') {
        Browser.open({ url: urlMarket })
        next(false)
      }
    },
  },
]

const routeFinancialListview = () => import(/* webpackChunkName: "financial-processoscredito-listview" */ '@/views/modules/financial/pclistview/index.vue')
const routeFinancialCCListview = () => import(/* webpackChunkName: "financial-contacorrente-listview" */ '@/views/modules/financial/cclistview/index.vue')

export default [
  {
    path: '/financial/creditprocess/list',
    name: 'financial-listview-processos-credito',
    component: routeFinancialListview,
    meta: {
      resource: 'financial_listview_processoscredito',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.financial.Financeiro', /* <i18n path="modules.financial.Financeiro"></i18n> */
          active: true,
        },
        {
          text: 'modules.financial.Processos de crédito', /* <i18n path="modules.financial.Processos de crédito"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/financial/contacorrente/list',
    name: 'financial-listview-conta-corrente',
    component: routeFinancialCCListview,
    meta: {
      resource: 'financial_listview_contacorrente',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.financial.Financeiro', /* <i18n path="modules.financial.Financeiro"></i18n> */
          active: true,
        },
        {
          text: 'modules.financial.Conta corrente', /* <i18n path="modules.financial.Conta corrente"></i18n> */
          active: true,
        },
      ],
    },
  },
]

import axios from 'axios'
import i18n from '@/libs/i18n'
import apiDefaultConfig from './apiConfig'

export default class ApiService {
  axiosIns = null

  jwtConfig = { ...apiDefaultConfig }

  isAlreadyFetchingAccessToken = false

  subscribers = []

  skipRrefreshToken = 0

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.axiosIns.interceptors.request.use(
      config => {
        if (window.oVue !== undefined) {
          const source = axios.CancelToken.source()
          // eslint-disable-next-line no-param-reassign
          config.cancelToken = source.token

          // Nao adiciona os urls das rotas do refresh token e informação da conta
          if (config.url.includes('/users/refresh-token') || config.url.includes('/users/getInfo')) {
            //
          } else {
            window.oVue.$store.commit('request/addCancelToken', { token: source, url: config.url })
          }
        }

        const accessToken = this.getToken()
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        // eslint-disable-next-line no-param-reassign
        config.headers.Accept = 'application/json'
        // eslint-disable-next-line no-param-reassign
        config.headers.common.AppSource = 'ZomeMobile'
        // eslint-disable-next-line no-param-reassign
        config.headers.common.AppLang = i18n.locale || ''
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => response,
      async error => {
        const { config, response } = error
        const originalRequest = config
        let skipResponse401 = false

        if (config === undefined) {
          return Promise.reject(new Error(''))
        }

        if (config.url.includes('/users/refresh-token')) {
          skipResponse401 = true

          await new Promise(resolve => {
            this.setToken('')
            this.setRefreshToken('')
            if (typeof window.oVue !== 'undefined' && window.oVue !== null) {
              window.oVue.$root.$emit('app::showModalAuthLogin', resolve)
            }
          }).then(result => {
            if ((result !== null) && (result !== undefined) && ('accessToken' in result) && ('refreshToken' in result)) {
              this.isAlreadyFetchingAccessToken = false

              this.setToken(result.accessToken)
              this.setRefreshToken(result.refreshToken)

              this.onAccessTokenFetched(result.accessToken)

              if (typeof window.oVue !== 'undefined' && window.oVue !== null) {
                window.oVue.$root.$emit('app::hideModalAuthLogin')
              }
            }
          })

          return Promise.resolve(true)
        }

        if (skipResponse401 === false) {
          if (response && response.status === 401) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true

              this.refreshToken().then(r => {
                this.isAlreadyFetchingAccessToken = false

                if (r === true) {
                  // Ja tem token
                } else if ((r !== null) && (r !== undefined)) {
                  this.setToken(r.data.accessToken)
                  this.setRefreshToken(r.data.refreshToken)

                  this.onAccessTokenFetched(r.data.accessToken)
                }
              })
            }

            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(this.axiosIns(originalRequest))
              })
            })

            return retryOriginalRequest
          }
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  destroyToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  refreshToken() {
    return this.axiosIns.post(`${this.jwtConfig.configs.urlAPP}users/refresh-token`, {
      refreshToken: this.getRefreshToken(),
    })
  }

  refreshConfigs(configs) {
    this.jwtConfig.configs = configs || {}
  }

  query(resource, params) {
    return this.axiosIns.get(resource, params).catch(error => Promise.reject(error))
  }

  get(resource) {
    return this.axiosIns.get(`${resource}`).catch(error => Promise.reject(error))
  }

  post(resource, params) {
    return this.axiosIns.post(`${resource}`, params).catch(error => Promise.reject(error))
  }

  update(resource, params) {
    return this.axiosIns.put(`${resource}`, params).catch(error => Promise.reject(error))
  }

  put(resource, params) {
    return this.axiosIns.put(`${resource}`, params).catch(error => Promise.reject(error))
  }

  delete(resource) {
    return this.axiosIns.delete(resource).catch(error => Promise.reject(error))
  }
}

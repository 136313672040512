<template>
  <div>

    <template
      v-for="(item, index) in itens"
    >
      <h4
        v-if="item.title"
        :key="`alert-title-${index}`"
        class="mb-1 text-left"
      >
        {{ item.title }}:
      </h4>

      <dl
        v-if="item.itens.length > 0"
        :key="`alert-itens-itens-${index}`"
        class="text-left"
      >
        <dd
          v-for="(row, indexItem) in item.itens"
          :key="`alert-itens-item-${indexItem}`"
        >
          <p class="font-small-4">
            {{ row.txt }}
            <template v-if="row.type">
              <small class="ml-50 text-primary">({{ row.type }})</small>
            </template>
          </p>
        </dd>
      </dl>
    </template>

  </div>
</template>

<script>
export default {
  props: {
    itens: {
      type: Array,
      required: true,
    },
  },
}
</script>

import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import btoa from 'btoa'
import defaultState from './defaultState'
import { renderInfoExtraDataRow } from './helper'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
      currentPage: state.currentPage.value,
      searchTxt: state.searchTxt.value,
      totalFieldsFilter: state.totalFieldsFilter.value,
      showListviewFilter: state.showListviewFilter.value,
      showListview: () => {
        if (state.totalRows.value > 0) {
          return true
        }

        return false
      },
      showPager: () => {
        if (state.totalRows.value > state.perPage.value) {
          return true
        }

        return false
      },
      filterByStatus: state.filterByStatus.value,
      filterByType: state.filterByType.value,
    }),
    txt: state => ({
      searchPlaceholder: state.txtSearchPlaceholder,
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    fieldsOrder: state => state.fieldsOrder,
    fieldsFilter: state => ({
      status: state.statusFilter,
      statusDashboard: state.statusFilterDashboard.value,
    }),
    tiposSeguimentos: (state, getters, rootState) => {
      if ('crm_leads_form' in rootState) {
        if ('tiposSeguimentos' in rootState.crm_leads_form) {
          return [{
            sw090s01: 'all',
            sw090s02: i18n.t('Todos'),
          }].concat(rootState.crm_leads_form.tiposSeguimentos)
        }
      }

      return []
    },
    perms: state => ({
      delete: state.canDelete,
      edit: state.canEdit,
    }),
    formVars: state => ({
      usersCreate: state.usersCreateSeguimento.value,
      tipos: state.tiposSeguimentos.value,
      diaDaSemana: state.diaDaSemana.value,
      diaSemana: state.diaSemana.value,
      typeWeekMonth: state.typeWeekMonth.value,
      typeSend: state.typeSend.value,
      optionsNovo: state.optionsNovoSeguimento.value,
      optionsStatusDate: state.optionsStatusDate,
    }),
    infoSeguimento: state => ({
      datasRoutina: state.datasRoutina.value,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    addFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.totalFieldsFilter.value = 0
    },
    setShowListviewFilter(state, payload) {
      state.showListviewFilter.value = payload
    },
    setFilterByStatus(state, payload) {
      state.filterByStatus.value = payload
    },
    setFilterByType(state, payload) {
      state.filterByType.value = payload
    },
    setCanDelete(state, payload) {
      state.canDelete = payload || false
    },
    setCanEdit(state, payload) {
      state.canEdit = payload || false
    },
    renderInfoExtraData(state) {
      if (state.listviewData.value.length > 0) {
        state.listviewData.value.forEach((row, index) => {
          const oExtra = renderInfoExtraDataRow(state, row)
          state.listviewData.value[index].extra = oExtra || {}
        })
      }
    },
    setUsersCreateSeguimento(state, payload) {
      state.usersCreateSeguimento.value = payload || []
    },
    setTiposSeguimentos(state, payload) {
      state.tiposSeguimentos.value = payload || []
    },
    setDiaDaSemana(state, payload) {
      state.diaDaSemana.value = payload || []
    },
    setDiaSemana(state, payload) {
      state.diaSemana.value = payload || []
    },
    setTypeWeekMonth(state, payload) {
      state.typeWeekMonth.value = payload || []
    },
    setTypeSend(state, payload) {
      state.typeSend.value = payload || []
    },
    setOptionsNovoSeguimento(state, payload) {
      state.optionsNovoSeguimento.value = payload || []
    },
    setDatasRotina(state, payload) {
      state.datasRoutina.value = payload || []
    },
    setStatusFilterDashboard(state, payload) {
      state.statusFilterDashboard.value = payload || []
    },
  },
  actions: {

    async loadListviewData({
      state, commit, dispatch, rootGetters,
    }, payload) {
      if (rootGetters['crm_leads_form/loaded'] === true) {
        await dispatch('request/cancelTokensPendingByUrl', 'leads/loadSeguimentosByLead', { root: true })
      }

      const request = await new Promise((resolve, reject) => {
        commit('resetFilterSelectedTotalFields')

        let sFilterStatus = 'todos'
        let sFilterType = 'all'

        if ((state.filterByStatus.value !== null) && (state.filterByStatus.value !== '')) {
          if ('tab' in state.filterByStatus.value) {
            sFilterStatus = state.filterByStatus.value.tab
          }
        }

        if ((state.filterByType.value !== null) && (state.filterByType.value !== '')) {
          if ('sw090s01' in state.filterByType.value) {
            sFilterType = state.filterByType.value.sw090s01
          }
        }

        let urlGetSeguimentos = ''
        if (rootGetters['crm_leads_form/keyReg'] !== '') {
          urlGetSeguimentos = `leads/loadSeguimentosByLead/${rootGetters['crm_leads_form/keyReg']}/${sFilterStatus}/${sFilterType}/${state.currentPage.value}/${state.perPage.value}`
        } else if (rootGetters['crm_leads_form/dynamicIDForm'] !== '') {
          urlGetSeguimentos = `leads/loadSeguimentosByLead/${rootGetters['crm_leads_form/dynamicIDForm']}/${sFilterStatus}/${sFilterType}/${state.currentPage.value}/${state.perPage.value}`
        }

        if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
          commit('setListviewInit', 1)
        } else {
          commit('setListviewData', [])
          commit('setTotalRows', 0)
          commit('setLoadingListview', true)
          commit('setListviewInit', 1)
        }

        if (urlGetSeguimentos !== '') {
          api.post(`${apiConfig.url_base_api}${urlGetSeguimentos}`)
            .then(response => {
              try {
                if (response.data !== undefined) {
                  if ('itens' in response.data) {
                    commit('setListviewData', response.data.itens)
                  }

                  if (('allowDelete' in response.data) && (Number(response.data.allowDelete) === 1)) {
                    commit('setCanDelete', true)
                  }

                  if (('allowEdit' in response.data) && (Number(response.data.allowEdit) === 1)) {
                    commit('setCanEdit', true)
                  }

                  commit('renderInfoExtraData')

                  commit('setTotalRows', response.data.total)
                  commit('setLoadingListview', false)
                  commit('setListviewInit', 0)

                  if (!!payload && (payload.init !== undefined) && (payload.init === true) && (response.data.total > 0)) {
                    commit('setShowListviewFilter', true)
                  }

                  resolve(true)
                } else {
                  reject(new Error(i18n.t('Problema ao carregar os seguimentos')))
                }
              } catch (error) {
                if ((error.response !== undefined) && (error.response.status !== undefined)) {
                  reject(new Error(i18n.t('Problema ao carregar os seguimentos')))
                }
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os seguimentos')))
              }
            })
        }
      })

      return request
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('loadListviewData')
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true })
    },

    setFilterByStatus({ commit, dispatch }, value) {
      commit('setFilterByStatus', value)
      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true })
    },

    setFilterByType({ commit, dispatch }, value) {
      commit('setFilterByType', value)
      commit('setCurrentPage', 1)
      return dispatch('loadListviewData', { loadingDisable: true })
    },

    async getNotesBySeguimentos({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadNotesBySeguimento', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw103s03', payload.sw103s03 || '')

        api.post(`${apiConfig.url_base_api}leads/loadNotesBySeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if ('notes' in response.data) {
                  const aNotes = response.data.notes

                  aNotes.forEach((row, index) => {
                    aNotes[index].edition = false
                  })

                  resolve(aNotes)
                } else {
                  reject(new Error(i18n.t('Problema ao obter as notas do seguimento')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter as notas do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter as notas do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as notas do seguimento')))
            }
          })
      })
    },

    async addNoteToSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw103s02', payload.sw103s02 || '')
        formData.append('sw103s03', payload.sw103s03 || '')

        api.post(`${apiConfig.url_base_api}leads/addNoteToSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a nota')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a nota')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a nota')))
            }
          })
      })
    },

    async deleteNoteSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw103s01', payload.sw103s01 || '')

        api.post(`${apiConfig.url_base_api}leads/deleteNoteSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar a nota')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar a nota')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar a nota')))
            }
          })
      })
    },

    async updateNoteSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw103s01', payload.sw103s01 || '')
        formData.append('sw103s02', payload.sw103s02 || '')

        api.post(`${apiConfig.url_base_api}leads/updateNoteSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao alterar a nota')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao alterar a nota')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a nota')))
            }
          })
      })
    },

    async popupAddSeguimento({ dispatch, commit }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/popupAddSeguimento', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('optionsNovoSeguimento[sw025s80]', payload.typeLead || '')
        formData.append('optionsNovoSeguimento[sw025s01]', payload.keyReg || '')
        formData.append('optionsNovoSeguimento[tipo]', payload.tipo || '')

        api.post(`${apiConfig.url_base_api}leads/popupAddSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                if (checkNested(response.data.data, 'aUsersCreateSeguimento')) {
                  commit('setUsersCreateSeguimento', response.data.data.aUsersCreateSeguimento)
                }

                if (checkNested(response.data.data, 'tiposSeguimentos')) {
                  commit('setTiposSeguimentos', response.data.data.tiposSeguimentos)
                }

                if (checkNested(response.data.data, 'diaDaSemana')) {
                  commit('setDiaDaSemana', response.data.data.diaDaSemana)
                }

                if (checkNested(response.data.data, 'diaSemana')) {
                  commit('setDiaSemana', response.data.data.diaSemana)
                }

                if (checkNested(response.data.data, 'typeWeekMonth')) {
                  commit('setTypeWeekMonth', response.data.data.typeWeekMonth)
                }

                if (checkNested(response.data.data, 'typeSend')) {
                  commit('setTypeSend', response.data.data.typeSend)
                }

                if (checkNested(response.data.data, 'optionsNovoSeguimento')) {
                  commit('setOptionsNovoSeguimento', response.data.data.optionsNovoSeguimento)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao criar novo seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao criar novo seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao criar novo seguimento')))
            }
          })
      })
    },

    async saveSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/saveSeguimento`, payload)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar o seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar o seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar o seguimento')))
            }
          })
      })
    },

    async changeStateSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s03', payload.sw030s03 || '')
        formData.append('sw030s08', payload.sw030s08 || '')
        formData.append('type', payload.type || '')
        formData.append('note', payload.note || '')

        let sUrlRequest = 'leads/changeStateSeguimento'
        if ('action' in payload) {
          if (payload.action === 'rotina') {
            sUrlRequest = 'leads/changeStateSeguimentoRotina'
          } else if (payload.action === 'campanha') {
            sUrlRequest = 'leads/changeStateSeguimentoCampanha'
          }
        }

        api.post(`${apiConfig.url_base_api}${sUrlRequest}`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao atualizar o estado do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao atualizar o estado do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar o estado do seguimento')))
            }
          })
      })
    },

    async changeStateSeguimentoRotina(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s03', payload.sw030s03 || '')
        formData.append('sw030s08', payload.sw030s08 || '')
        formData.append('type', payload.type || '')
        formData.append('note', payload.note || '')

        api.post(`${apiConfig.url_base_api}leads/changeStateSeguimentoRotina`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao concluir a rotina do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao concluir a rotina do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao concluir a rotina do seguimento')))
            }
          })
      })
    },

    async removeSeguimentoRotina(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s03', payload.sw030s03 || '')
        formData.append('sw030s08', payload.sw030s08 || '')
        formData.append('type', payload.type || '')
        formData.append('note', payload.note || '')

        api.post(`${apiConfig.url_base_api}leads/changeStateSeguimentoRotina`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar a rotina do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar a rotina do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar a rotina do seguimento')))
            }
          })
      })
    },

    async removeSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s03', payload.sw030s03 || '')
        formData.append('sw030s08', payload.sw030s08 || '')
        formData.append('type', payload.type || '')
        formData.append('note', payload.note || '')

        api.post(`${apiConfig.url_base_api}leads/changeStateSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao eliminar o seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao eliminar o seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar o seguimento')))
            }
          })
      })
    },

    async updateSeguimento(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s05', payload.sw030s05 || '')

        api.post(`${apiConfig.url_base_api}leads/updateSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao editar o seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao editar o seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao editar o seguimento')))
            }
          })
      })
    },

    async popupEditSeguimentoUserResp({ dispatch, commit }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/popupEditSeguimentoUserResp', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')

        api.post(`${apiConfig.url_base_api}leads/popupEditSeguimentoUserResp`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                if (checkNested(response.data.data, 'aUsersCreateSeguimento')) {
                  commit('setUsersCreateSeguimento', response.data.data.aUsersCreateSeguimento)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao obter os reponsáveis do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter os reponsáveis do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter os reponsáveis do seguimento')))
            }
          })
      })
    },

    async updateSeguimentoUserResp(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s02', payload.sw030s02 || '')

        api.post(`${apiConfig.url_base_api}leads/updateSeguimentoUserResp`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao alterar o responsável do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao alterar o responsável do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar o responsável do seguimento')))
            }
          })
      })
    },

    async updateSeguimentoData(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')
        formData.append('sw030s04', payload.sw030s04 || '')
        formData.append('sw030s04_time', payload.sw030s04_time || '')
        formData.append('sw030s18', payload.sw030s18 || '')

        api.post(`${apiConfig.url_base_api}leads/updateSeguimentoData`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.erro === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
            }
          })
      })
    },

    async updateEvent(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('swid', payload.swid || '')
        formData.append('swdate', payload.swdate || '')
        formData.append('swOldDate', payload.swOldDate || '')
        formData.append('swtype', payload.swtype || '')
        formData.append('swchk', payload.swchk || '')

        api.post(`${apiConfig.url_base_api}leads/updateEvent`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
            }
          })
      })
    },

    async getInfoExtraDataRow({ state }, payload) {
      return new Promise(resolve => {
        const oExtra = renderInfoExtraDataRow(state, payload)
        resolve(oExtra)
      })
    },

    async modalChangeSeguimento({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/display/modalChangeSeguimento', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('swid', payload.swid || '')
        formData.append('swDateNew', payload.swDateNew || '')
        formData.append('swDateOld', payload.swDateOld || '')
        formData.append('swtype', payload.swtype || '')
        formData.append('swAllDay', payload.swAllDay || '')

        api.post(`${apiConfig.url_base_api}leads/display/modalChangeSeguimento`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                resolve(response.data.data)
              } else {
                reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar a data do seguimento')))
            }
          })
      })
    },

    async popupEditRotinaUpdates({ dispatch, commit }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/popupEditRotinaUpdates', { root: true })

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw030s01', payload.sw030s01 || '')

        api.post(`${apiConfig.url_base_api}leads/popupEditRotinaUpdates`, formData)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                if (checkNested(response.data.data, 'rotinaUpdates')) {
                  commit('setDatasRotina', response.data.data.rotinaUpdates)
                } else {
                  commit('setDatasRotina', [])
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao obter o estado das datas da rotina')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter o estado das datas da rotina')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter o estado das datas da rotina')))
            }
          })
      })
    },

    async updateRotinaUpdates(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (payload.dates.length > 0) {
          payload.dates.forEach(row => {
            if (checkNested(row.sw100s04, 'id')) {
              formData.append('sw100s04[]', row.sw100s04.id)
            } else {
              formData.append('sw100s04[]', '')
            }

            formData.append('sw100s01[]', btoa(row.sw100s01))
          })
        }

        api.post(`${apiConfig.url_base_api}leads/updateRotinaUpdates`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else {
                  resolve({ message: response.data.msg })
                }
              } else {
                reject(new Error(i18n.t('Problema ao alterar o estado dos seguimentos')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao alterar o estado dos seguimentos')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao alterar o estado dos seguimentos')))
            }
          })
      })
    },

    async loadListviewDataDashboardByTab({ state, commit, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadSeguimentosByLeadDashboard', { root: true })

      const request = await new Promise((resolve, reject) => {
        commit('setListviewInit', 1)

        api.post(`${apiConfig.url_base_api}leads/loadSeguimentosByLeadDashboard/${payload.tab || 'all'}/seguimentos/${state.currentPage.value}/${state.perPageDashboard.value}`)
          .then(response => {
            try {
              if (response.data !== undefined) {
                if ('proximos' in response.data) {
                  commit('setListviewData', state.listviewData.value.concat(response.data.proximos))
                }

                if (('allowDelete' in response.data) && (Number(response.data.allowDelete) === 1)) {
                  commit('setCanDelete', true)
                }

                if (('allowEdit' in response.data) && (Number(response.data.allowEdit) === 1)) {
                  commit('setCanEdit', true)
                }

                commit('renderInfoExtraData')

                commit('setTotalRows', Number(response.data.total))
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                if (!!payload && (payload.init !== undefined) && (payload.init === true) && (response.data.total > 0)) {
                  commit('setShowListviewFilter', true)
                }

                let statusFinishData = false
                if (Number(response.data.total) <= Number(state.perPageDashboard.value)) {
                  statusFinishData = true
                }
                if (response.data.proximos.length === 0) {
                  statusFinishData = true
                }

                resolve({ finish: statusFinishData })
              } else {
                reject(new Error(i18n.t('Problema ao carregar os seguimentos')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os seguimentos')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os seguimentos')))
            }
          })
      })

      return request
    },

    async getTotalSeguimentosByDay({ state, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadSeguimentosByLeadDashboard', { root: true })

      const request = await new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/loadSeguimentosByLeadDashboard/histToday/seguimentos/${state.currentPage.value}/${state.perPageDashboard.value}`)
          .then(response => {
            try {
              if (response.data !== undefined) {
                resolve({ total: response.data.total })
              } else {
                reject(new Error(i18n.t('Problema ao obter o total de seguimentos do dia')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter o total de seguimentos do dia')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter o total de seguimentos do dia')))
            }
          })
      })

      return request
    },

    resetListviewDataDashboardByTab({ commit }) {
      commit('setListviewData', [])
      commit('setTotalRows', 0)
      commit('setLoadingListview', true)
      commit('setListviewInit', 1)
      commit('setCurrentPage', 1)
    },

    nextCurrentPage({ state, commit }) {
      commit('setCurrentPage', (state.currentPage.value + 1))
    },

    getTabsFilterDashboard({ commit, rootGetters }) {
      let oTabs = []
      const currentUser = rootGetters['auth/currentUser']

      if ((currentUser !== null) && (currentUser !== undefined)) {
        if (('id_role' in currentUser)) {
          if ('extras' in currentUser) {
            if (currentUser.extras.basic === 1) {
              oTabs = [{
                tab: 'histToday',
                txt: i18n.t('Do dia'),
              }, {
                tab: 'all',
                txt: i18n.t('Todos'),
              }]
            } else {
              oTabs = [{
                tab: 'histToday',
                txt: i18n.t('Do dia'),
              }, {
                tab: 'mySeguimentos',
                txt: i18n.t('Meus'),
              },
              {
                tab: 'equipa',
                txt: i18n.t('Equipa'),
              },
              {
                tab: 'all',
                txt: i18n.t('Todos'),
              }]
            }
          }
        }
      }

      commit('setStatusFilterDashboard', oTabs)
    },

    async cancelLoadListviewDataDashboardByTab({ dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadSeguimentosByLeadDashboard', { root: true })
    },

    async getInfoSeguimentoDashboard({ state, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/getInfoSeguimentoDashboard', { root: true })

      const request = await new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/getInfoSeguimentoDashboard/${payload.id}`)
          .then(response => {
            try {
              if (checkNested(response, 'data.seguimento.sw030s01')) {
                const oExtra = renderInfoExtraDataRow(state, response.data.seguimento)
                const data = response.data.seguimento
                data.extra = oExtra || {}
                resolve(data)
              } else {
                reject(new Error(i18n.t('Problema ao carregar a informação do seguimento')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar a informação do seguimento')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar a informação do seguimento')))
            }
          })
      })

      return request
    },

  },
}

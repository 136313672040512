<template>
  <div>
    <app-timeline
      v-if="listview.length > 0"
    >
      <app-timeline-item
        v-for="(row, index) in listview"
        :key="`lead-item-${index}`"
        :variant="checkStatusFinish(row.dataimport)"
        :class="{ 'cursor-pointer': true }"
        @click.stop.prevent="viewLead(row.sw453s01)"
      >
        <list-data-item
          :row="row"
          :view-lead="viewLead"
        />
      </app-timeline-item>
    </app-timeline>
    <div
      v-if="listview.length === 0"
      class="no-results text-center"
      :class="{'show': !listview.length}"
    >
      <h5 v-if="data.init === 1">
        {{ txt.loading }}
      </h5>
      <div v-if="(data.totalFieldsFilter===0) && (data.init === 0)">
        <b-alert
          class="mb-0 mt-1"
          variant="primary"
          show
        >
          <div class="alert-body">
            <span>{{ txt.default }}</span>
          </div>
        </b-alert>
      </div>
    </div>

    <infinite-loading
      ref="elmInfiniteLoading"
      force-use-infinite-wrapper="#modalImportLeads .b-modal-scrollarea-form"
      @infinite="getLeadsInfinite"
    >
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
  </div>
</template>

<script>
import store from '@/store'
import {
  VBTooltip, BAlert,
} from 'bootstrap-vue'
import {
  onMounted, ref,
} from '@vue/composition-api'
import { showMsgRequest } from '@core-custom/mixins/geral'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import InfiniteLoading from 'vue-infinite-loading'
import ListDataItem from './listDataItem.vue'

export default {
  components: {
    BAlert,
    AppTimeline,
    AppTimelineItem,
    InfiniteLoading,
    ListDataItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showMsgRequest],
  props: {
    listview: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    txt: {
      type: Object,
      default: () => {},
    },
    viewLead: {
      type: Function,
      require: true,
      default: null,
    },
    closeModal: {
      type: Function,
      require: true,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    checkStatusFinish(date) {
      const now = new Date()
      const dateImport = new Date(date)
      dateImport.setDate(dateImport.getDate() + 1)

      if (now < dateImport) {
        return 'success'
      }
      if (now > dateImport) {
        return 'danger'
      }

      return 'secondary'
    },
    async getLeadsInfinite() {
      await this.$store.dispatch('sw453/loadLeadsImport', {
        init: true,
      }).then(res => {
        if (typeof this.$refs.elmInfiniteLoading.stateChanger !== 'undefined') {
          if (res.finish === true) {
            this.$refs.elmInfiniteLoading.stateChanger.complete()
            if (this.listview.length === 0) {
              this.closeModal()

              if (localStorage.getItem('sw453_skip_leads') !== null && localStorage.getItem('sw453_skip_leads') !== '') {
                const now = new Date()
                localStorage.setItem('sw453_close_modal', now)
              }
            }
          } else {
            this.$refs.elmInfiniteLoading.stateChanger.loaded()
          }
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
        if (typeof this.$refs.elmInfiniteLoading.stateChanger !== 'undefined') {
          this.$refs.elmInfiniteLoading.stateChanger.loaded()
        }
      })

      await this.$store.dispatch('sw453/nextCurrentPage')
    },
  },
  setup() {
    const elmInfiniteLoading = ref(null)

    onMounted(() => {
      const init = async () => {
        try {
          if (elmInfiniteLoading) {
            await store.dispatch('sw453/resetListviewData')
            elmInfiniteLoading.value.stateChanger.reset()
          }
        } catch (err) {
        //
        }
      }

      init()
    })

    return {
      elmInfiniteLoading,
    }
  },
}
</script>

import { Device } from '@capacitor/device'
import { PushNotifications } from '@capacitor/push-notifications'
import { Network } from '@capacitor/network'
import { App } from '@capacitor/app'
import i18n from '@/libs/i18n'
import useAppConfig from '@core/app-config/useAppConfig'
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation'
import { Browser } from '@capacitor/browser'

let infoDevice = {}
let idDevice = null
let localeDevice = null

Device.getId().then(res => {
  idDevice = res.uuid
})

Device.getInfo().then(res => {
  infoDevice = res
})

Device.getLanguageCode().then(res => {
  localeDevice = res.value
})

const initNotificationDevice = () => {
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      PushNotifications.register()
    }
  })

  PushNotifications.addListener('registration', token => {
    localStorage.setItem('push-notification-token', token.value)
  })

  PushNotifications.addListener('registrationError', () => {
    //
  })

  PushNotifications.addListener('pushNotificationReceived', () => {
    //
  })

  PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    if ((notification.notification !== null) && (notification.notification !== undefined)) {
      if ('data' in notification.notification) {
        if (('pageView' in notification.notification.data) && (notification.notification.data.pageView === 'notification')) {
          if (('pageViewLink' in notification.notification.data) && (notification.notification.data.pageViewLink !== '')) {
            window.oVue.$root.$emit('app::redirectRouter', {
              path: notification.notification.data.pageViewLink,
            })
          }
        }
      }
    }
  })
}

const checkPermissionNotificationDevice = async () => PushNotifications.checkPermissions().then(result => result)

const initNetwork = async () => {
  const statusNetwork = await Network.getStatus()

  if (statusNetwork.connected === false) {
    window.oVue.$root.$emit('app::loading', {
      status: true, icon: 'WifiOffIcon', txt: i18n.t('Verifique a sua ligação à internet'), classExtra: 'text-danger font-weight-bold',
    })
  }

  Network.addListener('networkStatusChange', status => {
    if (status.connected === false) {
      window.oVue.$root.$emit('app::loading', {
        status: true, icon: 'WifiOffIcon', txt: i18n.t('Verifique a sua ligação à internet'), classExtra: 'text-danger font-weight-bold',
      })
    } else {
      window.oVue.$root.$emit('app::loading', false)
    }
  })
}

const checkDarkMode = () => {
  const sTemplateAppCustomByUser = localStorage.getItem('template-app-custom-user')

  // Obtem o modo automatico pelo dispositivo
  if (sTemplateAppCustomByUser === null) {
    const { skin } = useAppConfig()
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')

    if (['android'].includes(infoDevice.operatingSystem) && !['web'].includes(infoDevice.platform)) {
      const dark = window.navigator.userAgent.includes('AndroidDarkMode')
      if (dark === true) {
        skin.value = 'dark'
      } else {
        skin.value = 'light'
      }
    } else if ((prefersDark !== null) && (prefersDark !== undefined) && ('matches' in prefersDark) && (prefersDark.matches === true)) {
      skin.value = 'dark'
    } else {
      skin.value = 'light'
    }
  }
}

const openUrlNewTab = url => {
  Browser.open({ url })
}

const initApp = async () => {
  checkDarkMode()

  App.addListener('appStateChange', ev => {
    checkDarkMode()
    if (ev.isActive) {
      if (localStorage.getItem('newVersion') !== false && localStorage.getItem('sw453_close_modal') !== null && localStorage.getItem('sw453_close_modal') !== '') {
        const now = new Date()
        const dataClose = new Date(localStorage.getItem('sw453_close_modal'))
        dataClose.setHours(dataClose.getHours() + 1)

        if (dataClose.getTime() < now.getTime()) {
          localStorage.removeItem('sw453_close_modal')
          if (localStorage.getItem('sw453_skip_leads') !== null && localStorage.getItem('sw453_skip_leads') !== '') {
            localStorage.removeItem('sw453_skip_leads')
          }
          window.oVue.$root.$emit('app::callModalImportLeads')
        }
      }
    }
  })
}

const lockScreen = async () => {
  await ScreenOrientation.lock({ type: OrientationType.PORTRAIT })
}

const unlockScreen = async () => {
  await ScreenOrientation.unlock()
}

export default {
  isNative() {
    if (['ios', 'android'].includes(infoDevice.operatingSystem) && !['web'].includes(infoDevice.platform)) {
      return true
    }
    return false
  },
  getPlatform() {
    return infoDevice.operatingSystem || ''
  },
  getDeviceInfo() {
    return infoDevice
  },
  getDeviceID() {
    return idDevice
  },
  getDeviceLocale() {
    return localeDevice
  },
  initNotificationDevice,
  checkPermissionNotificationDevice,
  initNetwork,
  initApp,
  lockScreen,
  unlockScreen,
  openUrlNewTab,
}

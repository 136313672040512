import atob from 'atob'
import store from '@/store'
import { Browser } from '@capacitor/browser'

const routeToolboxIframe = () => import(/* webpackChunkName: "toolbox-iframe-link" */ '@/views/modules/toolbox/iframe.vue')

export default [
  {
    path: '/toolbox/view/:id',
    component: routeToolboxIframe,
    meta: {
      resource: 'Geral',
      action: 'manager',
      contentClass: 'page-iframe',
      renderBreadcrumb: (route, oStore) => {
        const aBreadcrumb = []
        aBreadcrumb.push({
          text: 'modules.toolbox.toolbox', /* <i18n path="modules.toolbox.toolbox"></i18n> */
          active: true,
        })
        const aToolboxLinks = oStore.getters['auth/toolboxLinks']

        if (aToolboxLinks.length > 0) {
          const infoToolbox = aToolboxLinks.find(o => o.sw047s01 === atob(route.params.id))

          if ((infoToolbox !== null) && (infoToolbox !== undefined) && ('sw047s02' in infoToolbox)) {
            aBreadcrumb.push({
              text: infoToolbox.sw047s02,
              active: true,
            })
          }
        }

        return aBreadcrumb
      },
    },
    beforeEnter(to, from, next) {
      const { id } = to.params || {}
      if (id !== '') {
        const aToolboxLinks = store.getters['auth/toolboxLinks']
        if (aToolboxLinks.length > 0) {
          const aInfoLink = aToolboxLinks.find(o => o.sw047s01 === atob(id))
          if ((aInfoLink.sw047s01 !== '') && (Number(aInfoLink.sw047s12) !== 1)) {
            Browser.open({ url: aInfoLink.sw047s03 })
            next(false)
          }
        }
      }
    },
  },
]

<template>
  <div>
    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
      <h6>{{ $t('Data de origem do Lead') }}
        <template v-if="row.dataimport !== ''">
          <small>{{ formateDate(row.dataimport) }}</small><b v-if="typeof currentUser.extras !== 'undefined' && typeof currentUser.extras.idLeader !== 'undefined' && currentUser.extras.idLeader !== '' && currentUser.extras.idLeader !== baseDecode(currentUser.id)"> - {{ row.nomeUser }}</b>
        </template>
      </h6>
    </div>

    <div
      v-if="(row.nome !== '' || row.apelido !== '')"
      class="d-flex flex-sm-row flex-column justify-content-between align-items-start mt-1"
    >
      <b-media>
        <h6>{{ row.nome }} {{ row.apelido }}</h6>
      </b-media>
    </div>

    <p>
      <template v-if="row.PID !== ''">
        <b>{{ row.PID }} - {{ row.tipoImovel }} <span v-if="typeof row.tipologia !== 'undefined' && row.tipologia !== null">{{ row.tipologia }}</span></b> {{ $t('em') }} {{ row.localizacao }}
      </template>
    </p>

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      size="sm"
      variant="outline-primary"
      @click.stop.prevent="viewLead(row.sw453s01)"
    >
      {{ $t('Tratar Lead') }}
    </b-button>

  </div>
</template>

<script>
import {
  BMedia, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  components: {
    BMedia,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    viewLead: {
      type: Function,
      require: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    formateDate(date) {
      return `${format(parseISO(date), 'yyyy-MM-dd')} ${format(parseISO(date), 'HH')}h${format(parseISO(date), 'mm')}`
    },
    baseDecode(val) {
      if (typeof val !== 'undefined') {
        return atob(val)
      }
      return ''
    },
  },
}
</script>

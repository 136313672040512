import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import checkNested from 'check-nested'
import { defaultState, defaultStateRouter } from './defaultState'

export default {
  namespaced: true,
  state: { ...defaultState(), ...defaultStateRouter() },
  getters: {
    mqShallShowLeftSidebar: state => state.mqShallShowLeftSidebar.value,
    tabContentStepShow: state => state.tabContentStepShow.value,
    form_data: state => state.form_data.value,
    form_sw095: state => state.form_sw095.value,
    load_data_geral: state => ({
      classificacaoLead: state.classificacaoLead.value,
      tipoClienteLead: state.tipoClienteLead.value,
      estadosLeadComprador: state.estadosLeadComprador.value,
      estadosLeadVendedor: state.estadosLeadVendedor.value,
      generos: state.generos.value,
      fonteContactos: state.fonteContactos.value,
      tiposNotificacaoDataAniversario: state.tiposNotificacaoDataAniversario.value,
      tiposContactosAdicionais: state.tiposContactosAdicionais.value,
      countries: state.countries.value,
      optionsYesNo: state.optionsYesNo.value,
      periodos: state.periodos.value,
      empresasCRM: state.empresasCRM.value,
      elegibilidadeContactoComercial: state.elegibilidadeContactoComercial.value,
      fonteContactoTiposAnuncio: state.fonteContactoTiposAnuncio.value,
      fonteContactoSitesImobiliarios: state.fonteContactoSitesImobiliarios.value,
      fonteContactoHUBs: state.fonteContactoHUBs.value,
      teamUsersCreate: state.teamUsersCreate.value,
      teamTabsCreate: state.teamTabsCreate.value,
      teamBookmarksCreate: state.teamBookmarksCreate.value,
      teamNameTabSelected: state.teamNameTabSelected.value,
      accessUserCreateLeadShareTipoRule: state.accessUserCreateLeadShareTipoRule.value,
      tiposSeguimentos: state.tiposSeguimentos,
    }),
    vars: state => ({
      userCandSendLeads: state.userCandSendLeads.value,
      userCandSendLeadsAsReference: state.userCandSendLeadsAsReference.value,
      typeUserCreateLead: state.typeUserCreateLead.value,
      newReference: state.newReference.value,
      nivelBlockEstadoComprador: state.nivelBlockEstadoComprador.value,
      nivelBlockEstadoVendedor: state.nivelBlockEstadoVendedor.value,
      previewPhotos: state.previewPhotos.value,
      showFieldComprador: state.showFieldComprador.value,
      showFieldVendedor: state.showFieldVendedor.value,
      isBlacklist: state.isBlacklist.value,
      infoConsentimento: state.infoConsentimento.value,
      importedIsMobile: state.importedIsMobile.value,
      showBlockTeam: state.showBlockTeam.value,
      executeActionShare: state.executeActionShare,
      isSavingData: state.isSavingData.value,
      sw025: state.form_data_sw025,
      sw095: state.form_data_sw095,
      templatesContactosAdicionais: state.templatesContactosAdicionais,
      updateRgpd: state.updateRgpd,
      sw029s01Assoc: state.sw029s01Assoc,
      sw029s01Nome: state.sw029s01Nome,
      paramsUrl: state.paramsUrl.value,
      typeActionForm: state.typeAction.value,
      convertContactToLead: state.convertContactToLead,
      permUserActionEdit: state.permUserActionEdit,
      campanha: {
        add: state.addCampanha,
        id: state.idCampanha,
        semana: state.semanaInicio,
        mes: state.mesInicio,
      },
      typeLeadsIDs: state.typeLeadsIDs,
    }),
    keyReg: state => state.keyReg.value,
    dynamicIDForm: state => state.dynamicIDForm.value,
    modeFormAction: state => state.modeFormAction.value,
    loaded: state => state.loaded.value,
    perms: (state, getters, rootState, rootGetters) => ({
      canShareLead: () => {
        try {
          if (
            (state.userCandSendLeads.value === 1)
            && (state.typeUserCreateLead.value === 0)
            && (state.newReference.value !== 1)
          ) {
            if (('sw025s01' in state.form_data_sw025) && ('sw025s75' in state.form_data_sw025) && (Number(state.form_data_sw025.sw025s75) === 1)) {
              return false
            }

            return true
          }

          return false
        } catch (err) {
          //
          return false
        }
      },
      canSendReference: () => {
        try {
          if (
            (state.userCandSendLeadsAsReference.value === 1)
            && (state.typeUserCreateLead.value === 0)
          ) {
            if (('sw025s01' in state.form_data_sw025) && ('sw025s75' in state.form_data_sw025) && (Number(state.form_data_sw025.sw025s75) === 1)) {
              return false
            }

            return true
          }

          return false
        } catch (err) {
          //
          return false
        }
      },
      canEdit: () => {
        try {
          if ((Number(state.permUserActionEdit) === 1) && (state.modeFormAction.value === 1)) {
            if (('sw025s01' in state.form_data_sw025) && ('sw025s75' in state.form_data_sw025) && (Number(state.form_data_sw025.sw025s75) === 1)) {
              return false
            }

            return true
          }

          return false
        } catch (err) {
          //
          return false
        }
      },
      disableButtonSave: () => {
        try {
          if (state.newReference.value === 1) {
            return true
          }

          return false
        } catch (err) {
          //
          return false
        }
      },
      canCreateListing: () => {
        try {
          const currentUser = rootGetters['auth/currentUser']
          if ((currentUser !== null) && (currentUser !== undefined)) {
            if ('extras' in currentUser) {
              if (('basic' in currentUser.extras) && (currentUser.extras.basic === 1)) {
                return true
              }

              if (('perm' in currentUser.extras) && (currentUser.extras.perm.includes('add_ang_C'))) {
                return true
              }
            }
          }
        } catch (err) {
          //
        }

        return false
      },
      showTabQualifComprador: () => ((state.typeUserCreateLead.value === 0) && (state.showFieldComprador.value === true)),
      showTabQualifVendedor: () => ((state.typeUserCreateLead.value === 0) && (state.showFieldVendedor.value === true)),
      showTabImoveisAngOrPreAng: () => ((state.typeUserCreateLead.value === 0) && (state.showFieldVendedor.value === true)),
      showTabImoveisInt: () => ((state.typeUserCreateLead.value === 1) || (state.typeUserCreateLead.value === 0)),
      isRecord: () => {
        try {
          if ('sw025s01' in state.form_data_sw025) {
            if (('sw025s75' in state.form_data_sw025) && (Number(state.form_data_sw025.sw025s75) === 1)) {
              return false
            }

            return true
          }

          return false
        } catch (err) {
          //
          return false
        }
      },
    }),
    formModeView: state => ((state.modeFormAction.value === 1)),
    alertsRGPD: state => state.alertsRGPD.value,
    messageInfo: state => state.messageInfo,
  },
  mutations: {
    resetState(state) {
      const updateState = { ...defaultState() }
      const defaultStateRouterDefault = defaultStateRouter()

      if (Object.keys(defaultStateRouterDefault).length > 0) {
        Object.keys(defaultStateRouterDefault).forEach(index => {
          updateState[index] = state[index]
        })
      }

      Object.assign(state, updateState)
    },
    setMqShallShowLeftSidebar(state, payload) {
      state.mqShallShowLeftSidebar.value = payload
    },
    setTabContentStepShow(state, payload) {
      state.tabContentStepShow.value = payload
    },
    setClassificacaoLead(state, payload) {
      state.classificacaoLead.value = payload
    },
    setTipoClienteLead(state, payload) {
      state.tipoClienteLead.value = payload
    },
    setEstadosLeadComprador(state, payload) {
      state.estadosLeadComprador.value = payload
    },
    setEstadosLeadVendedor(state, payload) {
      state.estadosLeadVendedor.value = payload
    },
    setGeneros(state, payload) {
      state.generos.value = []
      if (typeof payload === 'object') {
        Object.keys(payload).forEach(index => {
          state.generos.value.push({
            id: index,
            txt: payload[index],
          })
        })
      } else {
        state.generos.value = payload
      }
    },
    setFonteContactos(state, payload) {
      state.fonteContactos.value = []
      if (Array.isArray(payload)) {
        state.fonteContactos.value = payload
      }
    },
    setTiposNotificacaoDataAniversario(state, payload) {
      if (typeof payload === 'object') {
        Object.keys(payload).forEach(index => {
          state.tiposNotificacaoDataAniversario.value.push({
            id: index || 0,
            txt: payload[index],
          })
        })
      }
    },
    setTiposContactosAdicionais(state, payload) {
      state.tiposContactosAdicionais.value = []
      if (Array.isArray(payload)) {
        payload.forEach(o => {
          state.tiposContactosAdicionais.value.push({
            id: o.sw096s01,
            txt: o.sw096s02,
          })
        })
      }
    },
    setCountries(state, payload) {
      state.countries.value = []
      if (typeof payload === 'object') {
        Object.keys(payload).forEach(index => {
          state.countries.value.push({
            id: index,
            txt: payload[index],
          })
        })
      }
    },
    setMapLatLong(state, payload) {
      try {
        if (payload.lat && payload.lng) {
          state.form_data.value.sw025s25 = `${payload.lat}###${payload.lng}`
        } else {
          state.form_data.value.sw025s25 = ''
        }
      } catch (err) {
        state.form_data.value.sw025s25 = ''
      }
    },
    setUpdateFormData(state, payload) {
      if (payload === null || payload === '') {
        state.form_data.value = {}
      } else {
        state.form_data.value = { ...payload }
      }

      // Depois de atualizar os valores, atualiza a variaveis para usar no template nas condicoes
      if ((state.form_data.value !== null) && (state.form_data.value !== undefined)) {
        if ('sw027s03' in state.form_data.value) {
          if (Array.isArray(state.form_data.value.sw027s03)) {
            let showFieldComprador = 0
            let showFieldVendedor = 0
            if (state.form_data.value.sw027s03.length > 0) {
              state.form_data.value.sw027s03.forEach(o => {
                if ([2, 4].includes(Number(o.sw022s01))) {
                  showFieldComprador += 1
                }
                if ([1, 3].includes(Number(o.sw022s01))) {
                  showFieldVendedor += 1
                }
              })
            }

            state.showFieldComprador.value = (showFieldComprador > 0)
            state.showFieldVendedor.value = (showFieldVendedor > 0)
          }
        } else {
          state.showFieldComprador.value = false
          state.showFieldVendedor.value = false
        }
      } else {
        state.showFieldComprador.value = false
        state.showFieldVendedor.value = false
      }
    },
    addNewContactAdditional(state, payload) {
      if (payload !== undefined) {
        state.form_sw095.value.push(payload)
      } else {
        state.form_sw095.value.push({
          sw095s01: null,
          sw095s03: null,
          sw095s04: null,
          sw095s05: null,
          template: null,
        })
      }
    },
    resetContactAdditional(state, payload) {
      if (Array.isArray(state.form_sw095.value)) {
        if (payload.index in state.form_sw095.value) {
          state.form_sw095.value[payload.index] = {
            sw095s01: null,
            sw095s03: payload.sw095s03 || null,
            sw095s04: null,
            sw095s05: null,
            template: payload.template || null,
          }
        }
      }
    },
    removeContactAdditional(state, payload) {
      if (state.form_sw095.value !== undefined) {
        if (payload in state.form_sw095.value) {
          state.form_sw095.value.splice(payload, 1)
        }
      }
    },
    clearContactAdditional(state) {
      state.form_sw095.value = []
    },
    setDynamicIDForm(state, payload) {
      state.dynamicIDForm.value = payload || ''
    },
    setModeViewDetail(state, payload) {
      state.modeFormAction.value = payload
    },
    setOptionsYesNo(state, payload) {
      state.optionsYesNo.value = payload
    },
    setPeriodos(state, payload) {
      state.periodos.value = []
      if (Array.isArray(payload)) {
        state.periodos.value = payload
      }
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field) {
          state.form_data.value[payload.field] = payload.value || ''
        }
      } catch (err) {
        //
      }
    },
    setUpdateFormDataFieldSW095(state, payload) {
      try {
        if (payload.field) {
          const result = state.form_sw095.value
          result[payload.index][payload.field] = payload.value || ''

          state.form_sw095.value = [...result]
        }
      } catch (err) {
        //
      }
    },
    setLoaded(state, payload) {
      state.loaded.value = payload
    },
    setUserCandSendLeads(state, payload) {
      state.userCandSendLeads.value = payload || 0
    },
    setUserCandSendLeadsAsReference(state, payload) {
      state.userCandSendLeadsAsReference.value = payload || 0
    },
    setTypeUserCreateLead(state, payload) {
      state.typeUserCreateLead.value = Number(payload) || 0
    },
    setNewReference(state, payload) {
      state.newReference.value = payload || 0
    },
    setParamsUrl(state, payload) {
      state.paramsUrl.value = payload
    },
    setTypeAction(state, payload) {
      state.typeAction.value = payload || ''
    },
    setNivelBlockEstadoComprador(state, payload) {
      state.nivelBlockEstadoComprador.value = payload || 0
    },
    setNivelBlockEstadoVendedor(state, payload) {
      state.nivelBlockEstadoVendedor.value = payload || 0
    },
    setPreviewPhotos(state, payload) {
      state.previewPhotos.value = payload
    },
    setShowFieldComprador(state, payload) {
      state.showFieldComprador.value = payload || false
    },
    setShowFieldVendedor(state, payload) {
      state.showFieldVendedor.value = payload || false
    },
    setEmpresasLeadsCRM(state, payload) {
      state.empresasCRM.value = payload
    },
    setIsBlacklist(state, payload) {
      state.isBlacklist.value = payload || false
    },
    setElegibilidadeContactoComercial(state, payload) {
      state.elegibilidadeContactoComercial.value = payload
    },
    setInfoConsentimento(state, payload) {
      state.infoConsentimento.value = payload || {}
    },
    setFonteContactoTiposAnuncio(state, payload) {
      state.fonteContactoTiposAnuncio.value = payload
    },
    setFonteContactoSitesImobiliarios(state, payload) {
      state.fonteContactoSitesImobiliarios.value = payload
    },
    setFonteContactoHUBs(state, payload) {
      state.fonteContactoHUBs.value = payload
    },
    setImportedIsMobile(state, payload) {
      state.importedIsMobile.value = payload
    },
    setTeamUsersCreate(state, payload) {
      state.teamUsersCreate.value = []

      try {
        if (Array.isArray(payload)) {
          state.teamUsersCreate.value = payload
        } else if (Object.keys(payload).length > 0) {
          Object.keys(payload).forEach(index => {
            state.teamUsersCreate.value.push({
              id: index,
              name: payload[index] || '',
            })
          })
        }
      } catch (err) {
        //
      }
    },
    setTeamTabsCreate(state, payload) {
      state.teamTabsCreate.value = []

      try {
        if (Array.isArray(payload)) {
          state.teamTabsCreate.value = payload
        } else if (Object.keys(payload).length > 0) {
          Object.keys(payload).forEach(index => {
            state.teamTabsCreate.value.push({
              tab: index,
              name: payload[index] || '',
            })
          })
        }
      } catch (err) {
        //
      }
    },
    setTeamBookmarksCreate(state, payload) {
      state.teamBookmarksCreate.value = payload
    },
    setShowBlockTeam(state, payload) {
      state.showBlockTeam.value = payload
    },
    setTeamNameTabSelected(state, payload) {
      state.teamNameTabSelected.value = payload
    },
    setIsSavingData(state, payload) {
      state.isSavingData.value = payload
    },
    setAlertsRGPD(state, payload) {
      state.alertsRGPD.value = payload || []
    },
    setKeyReg(state, payload) {
      state.keyReg.value = payload
    },
    setFormDataSW025(state, payload) {
      state.form_data_sw025 = payload || {}
    },
    setFormDataSW095(state, payload) {
      state.form_data_sw095 = payload || {}
    },
    removeAlertsRGPD(state, payload) {
      if (state.alertsRGPD.value.length > 0) {
        const index = state.alertsRGPD.value.findIndex(o => btoa(o.sw256s01) === payload.sw256s01)
        if (index in state.alertsRGPD.value) {
          state.alertsRGPD.value.splice(index, 1)
        }
      }
    },
    setUpdateRGPD(state, payload) {
      state.updateRgpd = payload
    },
    setExecuteActionShare(state, payload) {
      state.executeActionShare = payload
    },
    setAssocCampanhaLead(state, payload) {
      state.addCampanha = 0
      state.idCampanha = ''
      state.semanaInicio = ''
      state.mesInicio = ''

      if ((payload !== null) && (payload !== undefined) && ('id' in payload)) {
        state.addCampanha = 1
        state.idCampanha = payload.id
        state.semanaInicio = payload.semana || ''
        state.mesInicio = payload.mes || ''
      }
    },
    setAccessUserCreateLeadShareTipoRule(state, payload) {
      state.accessUserCreateLeadShareTipoRule.value = payload || ''
    },
    setMessageInfo(state, payload) {
      state.messageInfo = payload || ''
    },
    setSW029s01Assoc(state, payload) {
      state.sw029s01Assoc = payload
    },
    setSW029s01Nome(state, payload) {
      state.sw029s01Nome = payload
    },
    setConvertContactToLead(state, payload) {
      state.convertContactToLead = payload || false
    },
    setPermUserActionEdit(state, payload) {
      state.permUserActionEdit = payload || 0
    },
    setTiposSeguimentos(state, payload) {
      state.tiposSeguimentos = payload || []
    },
    setCreateLeadContactSource(state, payload) {
      state.sourceCreateLeadContact = payload
    },
  },
  actions: {

    // Atualiza a posição do tabulador do formulario
    setTabContentStepShow({ commit }, payload) {
      commit('setTabContentStepShow', payload)
    },

    // Carrega os dados base do formulario de criação
    async getDataFormBase({ state, commit, dispatch }, payload) {
      let urlRequestData = ''

      if (state.typeAction.value === 'new-lead') {
        urlRequestData = 'leads/new'
      } else if (state.typeAction.value === 'new-contact') {
        urlRequestData = 'leads/contact/new'
      } else if (state.typeAction.value === 'new-reference') {
        urlRequestData = 'leads/refNew'
      }

      // Acrescenta o tabulador
      const { tab } = state.paramsUrl.value

      if ((tab !== null) && (tab !== undefined) && (tab !== '')) {
        urlRequestData = `leads/${tab}/new`
      }

      await dispatch('request/cancelTokensPendingByUrl', urlRequestData, { root: true })

      const request = new Promise((resolve, reject) => {
        const { keyReg } = payload

        if ((keyReg !== undefined) && (keyReg !== '')) {
          urlRequestData = `leads/edit/${keyReg}`

          if (state.modeFormAction.value === 1) {
            urlRequestData = `leads/viewDetail/${keyReg}`
          }
        }

        api.get(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(response.data.msg))
                } else if (typeof response.data.data === 'object') {
                  commit('setClassificacaoLead', response.data.data.classificacaoLead)
                  commit('setTipoClienteLead', response.data.data.tipoClienteLead)
                  commit('setEstadosLeadComprador', response.data.data.estadosLeadComprador)
                  commit('setEstadosLeadVendedor', response.data.data.estadosLeadVendedor)
                  commit('setGeneros', response.data.data.generoAgentes)
                  commit('setFonteContactos', response.data.data.fonteContactos)
                  commit('setTiposNotificacaoDataAniversario', response.data.data.aTiposNotificacoes)
                  commit('setTiposContactosAdicionais', response.data.data.tiposContactosAdicionais)
                  commit('setCountries', response.data.data.listCountry)
                  commit('setDynamicIDForm', response.data.data.dynamicIDForm)
                  commit('setOptionsYesNo', response.data.data.selectYesNo)
                  commit('setPeriodos', response.data.data.periodos)
                  commit('setUserCandSendLeads', response.data.data.userCandSendLeads)
                  commit('setUserCandSendLeadsAsReference', response.data.data.userCandSendLeadsAsReference)
                  commit('setTypeUserCreateLead', response.data.data.typeUserCreateLead || '')
                  commit('setNewReference', response.data.data.newReference)
                  commit('setPreviewPhotos', response.data.data.previewPhotos)
                  commit('setEmpresasLeadsCRM', response.data.data.empresasLeadsCRM)
                  commit('setElegibilidadeContactoComercial', response.data.data.elegibilidadeContactoComercial)
                  commit('setFonteContactoTiposAnuncio', response.data.data.fonteContactoTiposAnuncio)
                  commit('setFonteContactoSitesImobiliarios', response.data.data.fonteContactoSitesImob)
                  commit('setFonteContactoHUBs', response.data.data.fonteContactoAllHUB)

                  if ('tiposSeguimentos' in response.data.data) {
                    commit('setTiposSeguimentos', response.data.data.tiposSeguimentos)
                  }

                  if ('permUserActionEdit' in response.data.data) {
                    commit('setPermUserActionEdit', response.data.data.permUserActionEdit)
                  }

                  if ('blacklist' in response.data.data) {
                    commit('setIsBlacklist', response.data.data.blacklist)
                  } else {
                    commit('setIsBlacklist', false)
                  }

                  if ('nivelBlockEstadoComprador' in response.data.data) {
                    commit('setNivelBlockEstadoComprador', response.data.data.nivelBlockEstadoComprador)
                  } else {
                    commit('setNivelBlockEstadoComprador', 0)
                  }

                  if ('nivelBlockEstadoVendedor' in response.data.data) {
                    commit('setNivelBlockEstadoVendedor', response.data.data.nivelBlockEstadoVendedor)
                  } else {
                    commit('setNivelBlockEstadoVendedor', 0)
                  }

                  if ('rgpdAlerts' in response.data.data) {
                    commit('setAlertsRGPD', response.data.data.rgpdAlerts)
                  } else {
                    commit('setAlertsRGPD', [])
                  }

                  if ('accessUserCreateLeadShareTipo_rule' in response.data.data) {
                    commit('setAccessUserCreateLeadShareTipoRule', response.data.data.accessUserCreateLeadShareTipo_rule)
                  } else {
                    commit('setAccessUserCreateLeadShareTipoRule', '')
                  }

                  if ('modeViewDetail' in response.data.data) {
                    commit('setModeViewDetail', response.data.data.modeViewDetail)
                  } else {
                    commit('setModeViewDetail', '')
                  }

                  // Modo de edição
                  if ('sw025' in response.data.data) {
                    if ((response.data.data.sw025.sw025s80 === 0) || (response.data.data.sw025.sw025s80 === null)) {
                      commit('setTypeAction', 'new-lead')
                    }

                    if (response.data.data.sw025.sw025s80 === 1) {
                      commit('setTypeAction', 'new-contact')
                    }

                    if (checkNested(response, 'data.data.sw025.sw025s01') && (response.data.data.sw025.sw025s01 !== '')) {
                      commit('setKeyReg', btoa(response.data.data.sw025.sw025s01))
                    }

                    // Adiciona a variavel geral para tratamento dos dados
                    const oSW025 = response.data.data.sw025
                    oSW025.sw027s03 = response.data.data.sw027s03 || []

                    if (Number(oSW025.sw025s11) !== 1) {
                      oSW025.sw025s11 = 2
                    }

                    commit('setFormDataSW025', oSW025)

                    if ('contactosAdicionais' in response.data.data) {
                      commit('setFormDataSW095', response.data.data.contactosAdicionais)
                    }
                  }

                  resolve(true)
                } else {
                  reject(new Error(i18n.t('Problema ao carregar o formulário')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o formulário')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o formulário')))
            }
          })
      })

      return request
    },

    // Adicionar novo contacto adicional
    addNewContactAdditional({ state, commit }) {
      commit('addNewContactAdditional')
      commit('setUpdateFormData', state.form_data.value)
    },

    // Adicionar novo contacto adicional
    addNewContactAdditionalWithData({ state, commit }, payload) {
      commit('addNewContactAdditional', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    // Faz reset a info do contacto adicional
    resetContactAdditional({ state, commit }, payload) {
      commit('resetContactAdditional', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    // Atualiza coordena no mapa
    setMapLatLong({ state, commit }, payload) {
      commit('setMapLatLong', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    updateFieldValue({ state, commit }, payload) {
      if (Array.isArray(payload)) {
        payload.forEach(field => {
          commit('setUpdateFormDataField', field)
        })
      } else {
        commit('setUpdateFormDataField', payload)
      }
      commit('setUpdateFormData', state.form_data.value)
    },

    updateFieldSW095Value({ state, commit }, payload) {
      commit('setUpdateFormDataFieldSW095', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    setLoaded({ commit }) {
      commit('setLoaded', true)
    },

    setParamsUrl({ commit }, payload) {
      commit('setParamsUrl', payload)
    },

    setTypeAction({ commit }, payload) {
      commit('setTypeAction', payload)
    },

    setConvertContactToLead({ commit }, payload) {
      commit('setConvertContactToLead', payload)
    },

    setSW029Assoc({ commit }, payload) {
      commit('setSW029s01Assoc', payload.id || '')
      commit('setSW029s01Nome', payload.name || '')
    },

    setTypeUserCreateLead({ commit }, payload) {
      commit('setTypeUserCreateLead', payload)
    },

    setInfoConsentimento({ commit }, payload) {
      commit('setInfoConsentimento', payload)
    },

    setImportedIsMobile({ commit }, payload) {
      commit('setImportedIsMobile', payload)
    },

    clearContactAdditional({ commit }) {
      commit('clearContactAdditional')
    },

    setShowBlockTeam({ commit }, payload) {
      commit('setShowBlockTeam', payload)
    },

    setIsSavingData({ commit }, payload) {
      commit('setIsSavingData', payload)
    },

    setModeViewDetail({ commit }, payload) {
      commit('setModeViewDetail', payload)
    },

    async getListUsersTeam({ state, commit, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/getListUsersTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('tab', payload.tabSelected)
        formData.append('createHtml', 1)
        formData.append('startMenuApp', '')

        api.post(`${apiConfig.url_base_api}leads/getListUsersTeam`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (typeof response.data.data === 'object') {
                  if ('users' in response.data.data) {
                    commit('setTeamUsersCreate', response.data.data.users)
                  }

                  /*
                  Comentado que por defeito nao devia carregar estas opções que dependem do consultor
                  if ('tabsUsers' in response.data.data) {
                    if ('tabs' in response.data.data.tabsUsers) {
                      commit('setTeamTabsCreate', response.data.data.tabsUsers.tabs)
                    }
                  }

                  if ('bookmarks' in response.data.data) {
                    commit('setTeamBookmarksCreate', response.data.data.bookmarks)
                  }
                  */

                  if ('nameTab' in response.data) {
                    commit('setTeamNameTabSelected', response.data.nameTab)
                  }

                  if ('tabSel' in response.data) {
                    if ((state.teamUsersCreate.value.length === 0) && (response.data.tabSel !== 'leads_do_leader')) {
                      reject(new Error(i18n.t('Nenhum consultor disponível')))
                    }
                  }

                  resolve(true)
                } else {
                  reject(new Error(i18n.t('Problema ao carregar os dados do formulário')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao carregar os dados do formulário')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os dados do formulário')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os dados do formulário')))
            }
          })
      })

      return request
    },

    async clearkeepAlive({ dispatch, rootGetters }) {
      // So termina se tiver sessao autenticada
      const isAuthenticated = rootGetters['auth/isAuthenticated']

      if (isAuthenticated === true) {
        await dispatch('request/cancelTokensPendingByUrl', 'leads/clearSWkeepalive', { root: true })

        const request = new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('idReg', rootGetters['crm_leads_form/keyReg'] || '')

          api.post(`${apiConfig.url_base_api}leads/clearSWkeepalive`, formData)
            .then(response => {
              try {
                if (typeof response.data === 'object') {
                  if (response.data.error === 0) {
                    resolve(true)
                  } else {
                    reject(new Error(i18n.t('Problema ao terminar a ediçao do registo')))
                  }
                } else {
                  reject(new Error(i18n.t('Problema ao terminar a ediçao do registo')))
                }
              } catch (error) {
                if ((error.response !== undefined) && (error.response.status !== undefined)) {
                  reject(new Error(i18n.t('Problema ao terminar a ediçao do registo')))
                }
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao terminar a ediçao do registo')))
              }
            })
        })

        return request
      }

      return null
    },

    async modalViewRequestRGPD({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'rgpd/modalPedidoDetalhes', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw254s01', payload.sw254s01)

        api.post(`${apiConfig.url_base_api}rgpd/modalPedidoDetalhes`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (typeof response.data.data === 'object') {
                  resolve(response.data.data)
                } else {
                  reject(new Error(i18n.t('Problema ao obter a informação do pedido RGPD')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter a informação do pedido RGPD')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação do pedido RGPD')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação do pedido RGPD')))
            }
          })
      })

      return request
    },

    async confirmRequestRGPD({ rootGetters, commit, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'rgpd/confirmRequestDone', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw254s01', payload.sw254s01 || '')
        formData.append('sw256s01', payload.sw256s01 || '')
        formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'] || '')

        api.post(`${apiConfig.url_base_api}rgpd/confirmRequestDone`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 1) {
                  reject(new Error(i18n.t('Problema ao confirmar a resolução do pedido')))
                } else {
                  commit('removeAlertsRGPD', { sw256s01: payload.sw256s01 })
                  resolve({ message: i18n.t('Confirmação efetuada com sucesso') })
                }
              } else {
                reject(new Error(i18n.t('Problema ao confirmar a resolução do pedido')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao confirmar a resolução do pedido')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao confirmar a resolução do pedido')))
            }
          })
      })

      return request
    },

    async getTotalCampanhas({ dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/campanhas/checkCampanhas', { root: true })

      const formData = new FormData()
      if (typeof payload !== 'undefined') {
        formData.append('sw025s01', payload.sw025s01 || '')
        formData.append('flagCancel', payload.flagCancel || false)
      }

      const request = new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/campanhas/checkCampanhas`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error === 0) {
                  resolve(response.data.total)
                } else {
                  reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
            }
          })
      })

      return request
    },

    async getCampanhas({ rootGetters, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/campanhas/popupListCampanhas', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('recrutamento', false)
        formData.append('idLead', rootGetters['crm_leads_form/keyReg'] || '')
        formData.append('start', payload.start || 0)
        formData.append('search', payload.search || '')
        formData.append('length', 50)

        api.post(`${apiConfig.url_base_api}leads/campanhas/popupListCampanhas`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (typeof response.data.data.campanhas === 'object') {
                  resolve({
                    campanhas: response.data.data.campanhas,
                    total: response.data.data.total,
                  })
                } else {
                  reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter o total de campanhas existentes')))
            }
          })
      })

      return request
    },

    async getCampanhasAtive({ rootGetters, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/campanhas/loadCampanhasAtivas', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('recrutamento', false)
        formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'] || '')

        api.post(`${apiConfig.url_base_api}leads/campanhas/loadCampanhasAtivas`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (typeof response.data.data === 'object') {
                  resolve({
                    campanhas: response.data.data,
                    total: response.data.recordsTotal,
                  })
                } else {
                  reject(new Error(i18n.t('Problema ao obter as campanhas ativas')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao obter as campanhas ativas')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter as campanhas ativas')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter as campanhas ativas')))
            }
          })
      })

      return request
    },

    async cancelCampanhaByLead({ rootGetters, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/campanhas/cancelCampanhaByLead', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw221s02', payload.sw221s02 || '')
        formData.append('sw221s04', rootGetters['crm_leads_form/keyReg'] || '')

        api.post(`${apiConfig.url_base_api}leads/campanhas/cancelCampanhaByLead`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if (response.data.error !== 1) {
                  resolve(true)
                } else {
                  reject(new Error(i18n.t('Problema ao cancelar a campanha')))
                }
              } else {
                reject(new Error(i18n.t('Problema ao cancelar a campanha')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao cancelar a campanha')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao cancelar a campanha')))
            }
          })
      })

      return request
    },

    async checkExistAllMyLeadsByEmail({ dispatch, rootGetters }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/checkExistAllMyLeads', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s05', '')
        formData.append('sw025s06', payload || '')
        formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'] || '')

        api.post(`${apiConfig.url_base_api}leads/checkExistAllMyLeads`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o email na base de dados')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o email na base de dados')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a verificação se já existe o email na base de dados')))
            }
          })
      })

      return request
    },

    async checkExistAllMyLeadsByPhone({ dispatch, rootGetters }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/checkExistAllMyLeads', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s05', payload || '')
        formData.append('sw025s06', '')
        formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'] || '')

        api.post(`${apiConfig.url_base_api}leads/checkExistAllMyLeads`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o email na base de dados')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o email na base de dados')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a verificação se já existe o email na base de dados')))
            }
          })
      })

      return request
    },

    async checkExistAllMyLeadsByNIForDNI({ dispatch, rootGetters }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/checkExistAllMyLeads', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s26', payload || '')
        formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'] || '')

        api.post(`${apiConfig.url_base_api}leads/checkExistAllMyLeads`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else if (rootGetters['auth/countryApp'] === 'es') {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o DNI na base de dados')))
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o NIF na base de dados')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                if (rootGetters['auth/countryApp'] === 'es') {
                  reject(new Error(i18n.t('Problema ao obter a verificação se já existe o DNI na base de dados')))
                } else {
                  reject(new Error(i18n.t('Problema ao obter a verificação se já existe o NIF na base de dados')))
                }
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              if (rootGetters['auth/countryApp'] === 'es') {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o DNI na base de dados')))
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o NIF na base de dados')))
              }
            }
          })
      })

      return request
    },

    async checkExistAllMyLeads({ dispatch, rootGetters }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/checkExistAllMyLeads', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw025s05', payload.sw025s05 || '')
        formData.append('sw025s06', payload.sw025s06 || '')
        formData.append('sw025s26', payload.sw025s26 || '')

        if (typeof payload.importLead === 'undefined') {
          formData.append('sw025s01', rootGetters['crm_leads_form/keyReg'] || '')
        } else {
          formData.append('sw025s01', '')
        }

        api.post(`${apiConfig.url_base_api}leads/checkExistAllMyLeads`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o registo na base de dados')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a verificação se já existe o registo na base de dados')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a verificação se já existe o registo na base de dados')))
            }
          })
      })

      return request
    },

    setAssocCampanhaLead({ commit }, payload) {
      commit('setAssocCampanhaLead', payload)
    },

    async save({ state, rootGetters, dispatch }) {
      // Campos escondidos existentes na aplicacao
      const formData = new FormData()
      formData.append('keyReg', rootGetters['crm_leads_form/keyReg'] || '')
      formData.append('dynamicIDForm', rootGetters['crm_leads_form/dynamicIDForm'] || '')

      if (state.typeUserCreateLead.value === 0) {
        formData.append('sw025s80', '')
      } else {
        formData.append('sw025s80', btoa(state.typeUserCreateLead.value))
      }

      // Adicionar nova angariacao
      if (checkNested(state.form_data.value, 'sw025s11.value') && (state.form_data.value.sw025s11.value === 1)) {
        formData.append('addNewAngariacao', 1)
      }

      if (state.typeUserCreateLead.value === 1) {
        formData.append('separadorLead', 'contactos')
      }

      if ('sw025s188' in state.form_data.value) {
        formData.append('separadorLead', state.form_data.value.sw025s188)
      }

      if (('separadorLead' in state.form_data.value) && ('tab' in state.form_data.value.separadorLead)) {
        formData.append('separadorLead', state.form_data.value.separadorLead.tab)
      }

      if (('idConsultorShare' in state.form_data.value) && ('id' in state.form_data.value.idConsultorShare)) {
        formData.append('idConsultorShare', btoa(state.form_data.value.idConsultorShare.id))
      }

      if ('bookmarksCreate' in state.form_data.value) {
        let sBookmarksCreate = ''

        if (state.form_data.value.bookmarksCreate.length > 0) {
          state.form_data.value.bookmarksCreate.forEach(bookmark => {
            sBookmarksCreate += ((sBookmarksCreate !== '') ? ';' : '')
            sBookmarksCreate += bookmark.sw075s01
          })

          formData.append('bookmarksCreate', btoa(sBookmarksCreate))
        }
      }

      if (state.convertContactToLead === true) {
        formData.append('contactToLead', 1)
        formData.append('sw029s01Assoc', state.sw029s01Assoc)
        formData.append('sw029s01Nome', state.sw029s01Nome)
        formData.append('separadorLead', '')
      } else {
        formData.append('contactToLead', '')
      }

      formData.append('executeActionShare', state.executeActionShare)

      // Parilhar lead
      if (state.executeActionShare === 1) {
        formData.append('idConsultorSend', state.form_data.value.idConsultorSend || '')
      }

      // Enviar referencia
      if (state.executeActionShare === 2) {
        formData.append('idConsultorRef', state.form_data.value.idConsultorRef || '')
        formData.append('RefValue', state.form_data.value.refValue || '')
        formData.append('RefObservacao', state.form_data.value.refObservacao || '')
        formData.append('RefIntencao', state.form_data.value.refIntencao || '')
        formData.append('RefHUBOrigem', state.form_data.value.refHUBOrigem || '')
      }

      formData.append('sw117s01', '')
      formData.append('createRegImportado', '')

      // RGPD
      formData.append('sw254', state.updateRgpd)

      formData.append('firstNameReg', state.form_data_sw025.sw025s03 || '')
      formData.append('sw025s152_old', state.form_data_sw025.sw025s152 || '')
      formData.append('sw025s153_old', state.form_data_sw025.sw025s153 || '')
      formData.append('sw025s205_old', state.form_data_sw025.sw025s205 || '')
      formData.append('sw025s206_old', state.form_data_sw025.sw025s206 || '')
      formData.append('sw025s197_old', state.form_data_sw025.sw025s197 || '')
      formData.append('blacklist', ((state.isBlacklist.value === true) ? 1 : 0))
      formData.append('sw025s08_old', state.form_data_sw025.sw025s08 || '')

      // Alteração do tratamento de toques nas campanhas (nao usados)
      formData.append('campanhaOptEmail', '')
      formData.append('campanhaOptEmailCom', '')
      formData.append('campanhaOptEmailPub', '')
      formData.append('campanhaOptChamada', '')
      formData.append('campanhaOptSms', '')
      formData.append('campanhaOptPostal', '')
      formData.append('campanhaOptTelemovel', '')

      // Associacao da campanha
      if (state.addCampanha === 1) {
        formData.append('addCampanha', 1)
        formData.append('idCampanha', state.idCampanha || '')
        formData.append('semanaInicio', state.semanaInicio || '')
        formData.append('mesInicio', state.mesInicio || '')
      }

      // Tabs
      // Geral
      await dispatch('saveDataEditionGeral').then(data => {
        [...data.entries()].forEach(row => {
          const [name, value] = row
          formData.append(name, value)
        })
      })

      // Dados
      await dispatch('saveDataEditionDados').then(data => {
        [...data.entries()].forEach(row => {
          const [name, value] = row
          formData.append(name, value)
        })
      })

      // Contactos
      await dispatch('saveDataEditionContactos').then(data => {
        [...data.entries()].forEach(row => {
          const [name, value] = row
          formData.append(name, value)
        })
      })

      // Qualificacao de vendedor
      await dispatch('saveDataEditionQualifVendedor').then(data => {
        [...data.entries()].forEach(row => {
          const [name, value] = row
          formData.append(name, value)
        })
      })

      // Morada
      await dispatch('saveDataEditionMorada').then(data => {
        [...data.entries()].forEach(row => {
          const [name, value] = row
          formData.append(name, value)
        })
      })

      // Observacao
      await dispatch('saveDataEditionObs').then(data => {
        [...data.entries()].forEach(row => {
          const [name, value] = row
          formData.append(name, value)
        })
      })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/save`, formData)
          .then(response => {
            try {
              let msgSuccessSave = ''
              const idChange = rootGetters['crm_leads_form/keyReg'] || ''

              if (idChange !== '') {
                if (state.typeUserCreateLead.value === state.typeLeadsIDs.contact) {
                  msgSuccessSave = i18n.t('O Contacto foi alterado com sucesso')
                } else if (state.typeUserCreateLead.value === state.typeLeadsIDs.lead) {
                  msgSuccessSave = i18n.t('O Lead foi alterado com sucesso')

                  if (('separadorLead' in state.form_data.value) && ('tab' in state.form_data.value.separadorLead)) {
                    msgSuccessSave = i18n.t('O Lead foi alterado com sucesso no separador {separador}', { separador: state.form_data.value.separadorLead.txt })
                  }
                }
              } else if (state.typeUserCreateLead.value === state.typeLeadsIDs.contact) {
                msgSuccessSave = i18n.t('O Contacto foi guardado com sucesso')
              } else if (state.typeUserCreateLead.value === state.typeLeadsIDs.lead) {
                msgSuccessSave = i18n.t('O Lead foi guardado com sucesso')

                if (('separadorLead' in state.form_data.value) && ('name' in state.form_data.value.separadorLead)) {
                  msgSuccessSave = i18n.t('O Lead foi guardado com sucesso no separador {separador}', { separador: `<b>${state.form_data.value.separadorLead.name}</b>` })
                }
              }

              if (typeof response.data === 'object') {
                if (Number(response.data.erro) === 1) {
                  reject(new Error(response.data.msg))
                } else if (checkNested(response.data, 'addNewListing') && (response.data.addNewListing === true)
                  && checkNested(response.data, 'idUserCreateAng') && (response.data.idUserCreateAng !== '')
                  && checkNested(response.data, 'idLead') && (response.data.idLead !== '')
                ) {
                  resolve({
                    save: true,
                    addNewListing: false, // response.data.addNewListing,
                    idUserCreateAng: response.data.idUserCreateAng,
                    idLead: response.data.idLead,
                    msgSuccessSave,
                  })
                } else {
                  resolve({ save: true, addNewListing: false, msgSuccessSave })
                }
              } else {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao guardar a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar a informação')))
            }
          })
      })
    },

    saveDataEditionGeral({ state }) {
      const formData = new FormData()
      formData.append('sw025s03', state.form_data.value.sw025s03 || '')
      formData.append('sw025s04', state.form_data.value.sw025s04 || '')
      formData.append('sw025s08', (checkNested(state.form_data.value, 'sw025s08.sw021s01') ? state.form_data.value.sw025s08.sw021s01 : ''))

      if (Array.isArray(state.form_data.value.sw027s03) && (state.form_data.value.sw027s03.length > 0)) {
        state.form_data.value.sw027s03.forEach(val => {
          if (val) {
            formData.append('sw027s03[]', val.sw022s01 || '')
          }
        })
      }

      formData.append('sw025s07', (checkNested(state.form_data.value, 'sw025s07.id') ? state.form_data.value.sw025s07.id : ''))
      formData.append('sw025s60', (checkNested(state.form_data.value, 'sw025s60.id') ? state.form_data.value.sw025s60.id : ''))
      formData.append('sw025s11', ((checkNested(state.form_data.value, 'sw025s11.value') && (state.form_data.value.sw025s11.value === 1)) ? 1 : 0))

      if (checkNested(state.form_data.value, 'linkImageLoadedUser')) {
        formData.append('linkImageLoadedUser', state.form_data.value.linkImageLoadedUser)
        formData.append('sw025s121valid', 1)
      }

      return formData
    },

    saveDataEditionDados({ state }) {
      const formData = new FormData()
      formData.append('sw025s109', state.form_data.value.sw025s109 || '')
      formData.append('sw025s10', (checkNested(state.form_data.value, 'sw025s10.id') ? state.form_data.value.sw025s10.id : ''))
      formData.append('sw025s13', (checkNested(state.form_data.value, 'sw025s13.sw023s01') ? state.form_data.value.sw025s13.sw023s01 : ''))
      formData.append('sw025s14', state.form_data.value.sw025s14 || '')
      formData.append('sw025s87', (checkNested(state.form_data.value, 'sw025s87.id') ? state.form_data.value.sw025s87.id : ''))
      formData.append('sw025s15', state.form_data.value.sw025s15 || '')
      formData.append('sw025s26', state.form_data.value.sw025s26 || '')
      formData.append('sw025s27', state.form_data.value.sw025s27 || '')
      formData.append('sw025s86', state.form_data.value.sw025s86 || '')
      formData.append('sw025s120', state.form_data.value.sw025s120 || '')

      // Empresa
      formData.append('sw025s113', state.form_data.value.sw025s113 || '')
      formData.append('sw025s114', (checkNested(state.form_data.value, 'sw025s114.sw101s01') ? state.form_data.value.sw025s114.sw101s01 : ''))
      formData.append('sw025s115', state.form_data.value.sw025s115 || '')

      // RGPD
      formData.append('sw025s197', (checkNested(state.form_data.value, 'sw025s197.id') ? state.form_data.value.sw025s197.id : ''))
      formData.append('sw025s152', state.form_data.value.sw025s152 || '')
      formData.append('sw025s205', state.form_data.value.sw025s205 || '')
      formData.append('sw025s206', state.form_data.value.sw025s206 || '')
      formData.append('sw025s153', state.form_data.value.sw025s153 || '')

      // Fonte de contacto
      if ((state.form_data.value !== null) && (state.form_data.value !== undefined)) {
        if ('sw025s13' in state.form_data.value) {
          if ((state.form_data.value.sw025s13 !== null) && (state.form_data.value.sw025s13 !== undefined)) {
            if ('sw023s06' in state.form_data.value.sw025s13) {
              if (state.form_data.value.sw025s13.sw023s06 === 'AO') {
                formData.append('sw025s221', (checkNested(state.form_data.value, 'sw025s221.id') ? state.form_data.value.sw025s221.id : ''))
                formData.append('sw025s222', state.form_data.value.sw025s222 || '')
              }

              if (state.form_data.value.sw025s13.sw023s06 === 'C') {
                formData.append('sw025s43', (checkNested(state.form_data.value, 'sw025s43.id') ? state.form_data.value.sw025s43.id : ''))
              }

              if (state.form_data.value.sw025s13.sw023s06 === 'M') {
                formData.append('sw025s44', (checkNested(state.form_data.value, 'sw025s44.sw002s01') ? state.form_data.value.sw025s44.sw002s01 : ''))
              }

              if (state.form_data.value.sw025s13.sw023s06 === 'L') {
                formData.append('sw025s45', (checkNested(state.form_data.value, 'sw025s45.id') ? state.form_data.value.sw025s45.id : ''))
              }

              if (state.form_data.value.sw025s13.sw023s06 === 'SI') {
                formData.append('sw025s63', (checkNested(state.form_data.value, 'sw025s63.sw066s01') ? state.form_data.value.sw025s63.sw066s01 : ''))
              }
            }
          }
        }
      }

      return formData
    },

    saveDataEditionContactos({ state }) {
      const formData = new FormData()
      formData.append('sw025s05', state.form_data.value.sw025s05 || '')
      formData.append('sw025s06', state.form_data.value.sw025s06 || '')

      if (Array.isArray(state.form_sw095.value) && (state.form_sw095.value.length > 0)) {
        state.form_sw095.value.forEach(row => {
          formData.append('sw095s03[]', (checkNested(row, 'sw095s03.id') ? row.sw095s03.id : ''))
          formData.append('sw095s04[]', row.sw095s04 || '')
          formData.append('sw095s05[]', row.sw095s05 || '')
          formData.append('sw095s06[]', row.sw095s06 || '')
        })
      }

      return formData
    },

    saveDataEditionQualifVendedor({ state }) {
      const formData = new FormData()
      formData.append('sw025s122', state.form_data.value.sw025s122 || '')
      formData.append('sw025s29', state.form_data.value.sw025s29 || '')
      formData.append('sw025s97', state.form_data.value.sw025s97 || '')
      formData.append('sw025s98', (checkNested(state.form_data.value, 'sw025s98.id') ? state.form_data.value.sw025s98.id : ''))
      formData.append('sw025s99', state.form_data.value.sw025s99 || '')
      formData.append('sw025s100', (checkNested(state.form_data.value, 'sw025s100.id') ? state.form_data.value.sw025s100.id : ''))
      formData.append('sw025s102', (checkNested(state.form_data.value, 'sw025s102.value') ? state.form_data.value.sw025s102.value : ''))
      formData.append('sw025s103', state.form_data.value.sw025s103 || '')
      formData.append('sw025s101', state.form_data.value.sw025s101 || '')
      formData.append('sw025s104', (checkNested(state.form_data.value, 'sw025s104.value') ? state.form_data.value.sw025s104.value : ''))
      formData.append('sw025s105', state.form_data.value.sw025s105 || '')
      return formData
    },

    saveDataEditionMorada({ state, rootGetters }) {
      const formData = new FormData()
      formData.append('sw025s90', state.form_data.value.sw025s90 || '')
      formData.append('sw025s91', (checkNested(state.form_data.value, 'sw025s91.id') ? state.form_data.value.sw025s91.id : ''))
      formData.append('sw025s92', state.form_data.value.sw025s92 || '')
      formData.append('sw025s25', state.form_data.value.sw025s25 || '')

      if (('sw025s90' in state.form_data.value) && (Number(state.form_data.value.sw025s90) === 1)) {
        //
      } else {
        if (rootGetters['auth/countryApp'] === 'pt') {
          formData.append('sw025s19', state.form_data.value.sw025s19 || '')
          formData.append('sw025s20', state.form_data.value.sw025s20 || '')
          formData.append('sw025s21', state.form_data.value.sw025s21 || '')
          formData.append('sw025s22', state.form_data.value.sw025s22 || '')
          formData.append('sw025s78', state.form_data.value.sw025s78 || '')
          formData.append('sw025s79', state.form_data.value.sw025s79 || '')
          formData.append('sw025s88', state.form_data.value.sw025s88 || '')
          formData.append('sw025s89', state.form_data.value.sw025s89 || '')
          formData.append('sw025s23', state.form_data.value.sw025s23 || '')
          formData.append('sw025s24', state.form_data.value.sw025s24 || '')
          formData.append('sw025s108', state.form_data.value.sw025s108 || '')
          formData.append('sw025s46', state.form_data.value.sw025s46 || '')
        }

        if (rootGetters['auth/countryApp'] === 'es') {
          formData.append('sw025s19', state.form_data.value.sw025s19 || '')
          formData.append('sw025s20', state.form_data.value.sw025s20 || '')
          formData.append('sw025s21', state.form_data.value.sw025s21 || '')
          formData.append('sw025s22', state.form_data.value.sw025s22 || '')
          formData.append('sw025s119', state.form_data.value.sw025s119 || '')
          formData.append('sw025s78', state.form_data.value.sw025s78 || '')
          formData.append('sw025s88', state.form_data.value.sw025s88 || '')
          formData.append('sw025s89', state.form_data.value.sw025s89 || '')
          formData.append('sw025s23', state.form_data.value.sw025s23 || '')
          formData.append('sw025s24', state.form_data.value.sw025s24 || '')
          formData.append('sw025s108', state.form_data.value.sw025s108 || '')
          formData.append('sw025s46', state.form_data.value.sw025s46 || '')
        }
      }

      return formData
    },

    saveDataEditionObs({ state }) {
      const formData = new FormData()
      formData.append('sw025s28', state.form_data.value.sw025s28 || '')
      return formData
    },

    async getSeparadoresByUserTeam({ commit, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/getSeparadoresByUserTeam', { root: true })

      const request = new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('user', payload.user)
        formData.append('lead', '')

        api.post(`${apiConfig.url_base_api}leads/getSeparadoresByUserTeam`, formData)
          .then(response => {
            try {
              if (typeof response.data === 'object') {
                if ('tabs' in response.data) {
                  commit('setTeamTabsCreate', response.data.tabs)
                }

                if ('bookmarks' in response.data) {
                  commit('setTeamBookmarksCreate', response.data.bookmarks)
                }

                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar os separadores dos consultor')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar os separadores do consultor')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os separadores do consultor')))
            }
          })
      })

      return request
    },

  },
}

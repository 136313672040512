import store from '@/store'

const routeCrmLeadsNew = () => import(/* webpackChunkName: "crm-leads-new" */ '@/views/modules/crm/leads/form/index.vue')
const routeCrmLeadListview = () => import(/* webpackChunkName: "crm-leads-listview" */ '@/views/modules/crm/leads/listview/index.vue')
const routeCrmVisitasListview = () => import(/* webpackChunkName: "crm-visitas-listview" */ '@/views/modules/crm/visitas/listview/index.vue')
const routeCrmProposalsListview = () => import(/* webpackChunkName: "crm-proposals-listview" */ '@/views/modules/crm/proposals/listview/index.vue')

export default [
  {
    path: '/crm/leads/new-contact',
    name: 'crm-leads-new-contact',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads_contact',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
        {
          text: 'modules.crm.Novo contacto', /* <i18n path="modules.crm.Novo contacto"></i18n> */
          active: true,
        },
      ],
    },
    props: { typeAction: 'new-contact' },
  },
  {
    path: '/crm/leads/new-lead',
    name: 'crm-leads-new-lead',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-leads-new-lead-menu-choose',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
        {
          text: 'modules.crm.Novo Lead', /* <i18n path="modules.crm.Novo Lead"></i18n> */
          active: true,
        },
      ],
    },
    props: { typeAction: 'new-lead' },
  },
  {
    path: '/crm/leads/new-lead-choose',
    name: 'crm-leads-new-lead-menu-choose',
    meta: {
      resource: 'crm_leads',
      action: 'new',
    },
    beforeEnter(to, from, next) {
      // Caso o utilizador tenha permissoes para aceder ao separador "Partilhadas", reecaminha para esta pagina
      const currentUser = store.getters['auth/currentUser']

      if ((currentUser !== null) && (currentUser !== undefined)) {
        if ('extras' in currentUser) {
          if (('perm' in currentUser.extras) && (currentUser.extras.perm.includes('partilhadas_C'))) {
            return next({ path: '/crm/leads/new-lead/partilhadas' })
          }
        }
      }

      return next({ path: '/crm/leads/new-lead' })
    },
  },
  {
    path: '/crm/leads/new-lead/:tab',
    name: 'crm-leads-new-lead-tab',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-leads-new-lead-menu-choose',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
        {
          text: 'modules.crm.Novo Lead', /* <i18n path="modules.crm.Novo Lead"></i18n> */
          active: true,
        },
      ],
    },
    props: { typeAction: 'new-lead' },
  },
  {
    path: '/crm/leads/edit/:id',
    name: 'crm-leads-edit',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-leads-new-lead-menu-choose',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
        {
          text: 'modules.crm.Edição', /* <i18n path="modules.crm.Edição"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/leads/view/:id',
    name: 'crm-leads-view',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-leads-listview',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
      ],
    },
    props: { viewDetail: true },
  },
  {
    path: '/crm/leads/contactToLead/new/:id',
    name: 'crm-leads-convert-contact-to-lead',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-leads-new-contact',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
        {
          text: 'modules.crm.Contacto para Lead', /* <i18n path="modules.crm.Contacto para Lead"></i18n> */
          active: true,
        },
      ],
    },
    props: { contactToLead: true, typeAction: 'new-lead' },
  },
  {
    path: '/crm/leads/new-reference',
    name: 'crm-leads-new-reference',
    component: routeCrmLeadsNew,
    meta: {
      resource: 'crm_leads_reference',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-leads-new-lead',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          to: '/crm/leads/list',
        },
        {
          text: 'modules.crm.Nova referência', /* <i18n path="modules.crm.Nova referência"></i18n> */
          active: true,
        },
      ],
    },
    props: { typeAction: 'new-reference' },
  },
  {
    path: '/crm/leads/list',
    name: 'crm-leads-listview',
    component: routeCrmLeadListview,
    meta: {
      resource: 'crm_leads',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/leads/visitas',
    name: 'crm-visitas-listview',
    component: routeCrmVisitasListview,
    meta: {
      resource: 'crm_visitas',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Visitas', /* <i18n path="modules.crm.Visitas"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/leads/visitas/new',
    name: 'crm-visitas-new',
    component: routeCrmVisitasListview,
    meta: {
      resource: 'crm_visitas',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-visitas-listview',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Visitas', /* <i18n path="modules.crm.Visitas"></i18n> */
          active: true,
        },
      ],
    },
    props: { createNew: true },
  },
  {
    path: '/crm/leads/propostas',
    name: 'crm-proposals-listview',
    component: routeCrmProposalsListview,
    meta: {
      resource: 'crm_propostas',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Propostas', /* <i18n path="modules.crm.Propostas"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/leads/propostas/new',
    name: 'crm-proposals-new',
    component: routeCrmProposalsListview,
    meta: {
      resource: 'crm_propostas',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-proposals-listview',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Propostas', /* <i18n path="modules.crm.Propostas"></i18n> */
          active: true,
        },
      ],
    },
    props: { createNew: true },
  },
  {
    path: '/crm/leads/propostas/new/:idvisita',
    name: 'crm-proposals-new-visita',
    component: routeCrmProposalsListview,
    meta: {
      resource: 'crm_propostas',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-proposals-listview',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Propostas', /* <i18n path="modules.crm.Propostas"></i18n> */
          active: true,
        },
      ],
    },
    props: { createNew: true },
  },
  {
    path: '/crm/leads/propostas/view/:idproposta',
    name: 'crm-proposals-view-visita',
    component: routeCrmProposalsListview,
    meta: {
      resource: 'crm_propostas',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'crm-proposals-listview',
      breadcrumbNavbar: [
        {
          text: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
        },
        {
          text: 'modules.crm.Propostas', /* <i18n path="modules.crm.Propostas"></i18n> */
          active: true,
        },
      ],
    },
    props: { modeView: true },
  },
]

import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'
import { renderInfoExtraDataRow } from './helper'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
    }),
    txt: state => ({
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    form_data: state => state.form_data.value,
    infoLead: state => state.infoLead.value,
    listingsBSM: state => state.listingsBSM.value,
    urlImgListing: state => state.urlImgListing.value,
    urlImgMarketListing: state => state.urlImgMarketListing.value,
    historyKeyStorage: state => state.historyKeyStorage,
    totalLeads: state => state.totalLeads.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setInfoLead(state, payload) {
      state.infoLead.value = payload
    },
    setListingsBSM(state, payload) {
      state.listingsBSM.value = payload
    },
    setUrlImgListing(state, payload) {
      state.urlImgListing.value = payload
    },
    setUrlImgMarketListing(state, payload) {
      state.urlImgMarketListing.value = payload
    },
    setTotalLeads(state, payload) {
      state.totalLeads.value = payload
    },
    setUpdateFormData(state, payload) {
      if (payload === null || payload === '') {
        state.form_data.value = {}
      } else {
        state.form_data.value = { ...payload }
      }
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field) {
          state.form_data.value[payload.field] = payload.value || ''
        }
      } catch (err) {
        //
      }
    },

  },
  actions: {
    async loadLeadsImport({ state, commit, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/loadlist', { root: true })

      const request = await new Promise((resolve, reject) => {
        commit('setListviewInit', 1)

        const formData = new FormData()

        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))
          stateCustom.forEach(field => {
            formData.append('sw453_skip_leads[]', field)
          })
        }

        api.post(`${apiConfig.url_base_api}leads/importacao/loadlist/${state.currentPage.value}`, formData)
          .then(response => {
            try {
              if (response.data !== undefined) {
                if ('leads' in response.data) {
                  commit('setListviewData', state.listviewData.value.concat(response.data.leads))
                }

                commit('setTotalRows', Number(response.data.total))
                commit('setTotalLeads', Number(response.data.totalLeads))
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                let statusFinishData = false
                if (Number(response.data.total) < Number(state.perPage.value)) {
                  statusFinishData = true
                }
                if (response.data.leads.length === 0) {
                  statusFinishData = true
                }

                resolve({ finish: statusFinishData })
              } else {
                reject(new Error(i18n.t('Problema ao carregar as Leads')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as Leads')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as Leads')))
            }
          })
      })

      return request
    },

    async getInfoLead({ state, dispatch, commit }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/getInfoLead', { root: true })

      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw453s01', btoa(payload.sw453s01) || '')

        api.post(`${apiConfig.url_base_api}leads/importacao/getInfoLead`, formData)
          .then(response => {
            try {
              if (!payload.checkButtons) {
                commit('setUpdateFormData', null)
              }
              if (response.data.error !== undefined && response.data.error !== 1) {
                const {
                  sw453s04, sw453s05, sw453s06, sw453s07,
                } = state.form_data.value

                commit('setInfoLead', response.data.data.sw453)
                commit('setListingsBSM', response.data.data.listingsBSM)
                commit('setUrlImgListing', response.data.data.imagesWebListings)
                commit('setUrlImgMarketListing', response.data.data.urlImgMarket)

                if (typeof state.infoLead.value.seguimento !== 'undefined') {
                  const oExtra = renderInfoExtraDataRow(state, state.infoLead.value.seguimento[0])
                  state.infoLead.value.seguimento[0].extra = oExtra || {}
                }

                if (!payload.checkButtons) {
                  commit('setUpdateFormDataField', { field: 'sw453s04', value: response.data.data.sw453.sw453s04 })
                  commit('setUpdateFormDataField', { field: 'sw453s05', value: response.data.data.sw453.sw453s05 })
                  commit('setUpdateFormDataField', { field: 'sw453s06', value: response.data.data.sw453.sw453s06 })
                  commit('setUpdateFormDataField', { field: 'sw453s07', value: response.data.data.sw453.sw453s07 })
                } else {
                  commit('setUpdateFormDataField', { field: 'sw453s04', value: sw453s04 })
                  commit('setUpdateFormDataField', { field: 'sw453s05', value: sw453s05 })
                  commit('setUpdateFormDataField', { field: 'sw453s06', value: sw453s06 })
                  commit('setUpdateFormDataField', { field: 'sw453s07', value: sw453s07 })
                }
                resolve(true)
              } else {
                reject(new Error(response.data.msg))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar a informação da Lead')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar a informação da Lead')))
            }
          })
      })

      return request
    },

    async updateLead({ state, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/updateLead', { root: true })

      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw453s01', btoa(payload.sw453s01) || '')
        formData.append('action', payload.action || '')

        api.post(`${apiConfig.url_base_api}leads/importacao/updateLead`, formData)
          .then(response => {
            if (payload.action === 'skip') {
              if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
                const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))
                if (stateCustom.length > 0) {
                  if (!stateCustom.includes(payload.sw453s01)) {
                    stateCustom.push(payload.sw453s01)
                  }

                  localStorage.setItem(state.historyKeyStorage, JSON.stringify(stateCustom))
                }
              } else {
                const oStatesHistory = []

                oStatesHistory.push(payload.sw453s01)

                localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
              }
            }
            resolve(response.data)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao fazer a ação na Lead')))
            }
          })
      })

      return request
    },

    async saveLead({ state, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/saveLead', { root: true })

      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw453s01', btoa(state.infoLead.value.sw453s01) || '')
        formData.append('sw453s04', state.form_data.value.sw453s04 || '')
        formData.append('sw453s05', state.form_data.value.sw453s05 || '')
        formData.append('sw453s06', state.form_data.value.sw453s06 || '')
        formData.append('sw453s07', state.form_data.value.sw453s07 || '')

        api.post(`${apiConfig.url_base_api}leads/importacao/saveLead`, formData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao gravar a Lead')))
            }
          })
      })

      return request
    },

    async getMarketStudy(state, payload) {
      return new Promise((resolve, reject) => {
        let urlRequestData = 'estudoMercado/search'
        const { id } = payload

        if ((id !== undefined) && (id !== '')) {
          urlRequestData = `estudoMercado/search/${id}`
        }

        api.post(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if (checkNested(response, 'data.casafariUrl')) {
                resolve(response.data.casafariUrl)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
            }
          })
      })
    },

    resetListviewData({ commit }) {
      commit('setListviewData', [])
      commit('setTotalRows', 0)
      commit('setLoadingListview', true)
      commit('setListviewInit', 1)
      commit('setCurrentPage', 1)
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('loadLeadsImport')
    },

    nextCurrentPage({ state, commit }) {
      commit('setCurrentPage', (state.currentPage.value + 1))
    },

    updateFieldValue({ state, commit }, payload) {
      if (Array.isArray(payload)) {
        payload.forEach(field => {
          commit('setUpdateFormDataField', field)
        })
      } else {
        commit('setUpdateFormDataField', payload)
      }
      commit('setUpdateFormData', state.form_data.value)
    },
  },
}

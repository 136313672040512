import store from '@/store'

export const configPhone = {
  mode: 'international',
  autoFormat: true,
  validCharactersOnly: true,
  defaultCountry: store.getters['auth/countryApp'],
  autoDefaultCountry: false,
  inputOptions: {
    autocomplete: 'off',
    autofocus: false,
    placeholder: '',
    maxlength: 25,
    type: 'tel',
    showDialCode: false,
  },
  dropdownOptions: {
    showDialCodeInSelection: false,
    showDialCodeInList: true,
    showFlags: true,
    showSearchBox: true,
    width: '100%',
  },
}

export const _ = null

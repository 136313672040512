import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    paramsUrl: state => state.paramsUrl.value,
    urlMarket: state => state.urlMarket.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setParamsUrl(state, payload) {
      state.paramsUrl.value = payload
    },
    setUrlMarketStudy(state, payload) {
      state.urlMarket.value = payload
    },
  },
  actions: {
    // Carrega o url para o iframe do Estudo do mercado
    async getMarketStudy({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let urlRequestData = 'estudoMercado/analise'
        const { id, general } = payload

        if ((id !== undefined) && (id !== '')) {
          urlRequestData = `estudoMercado/analise/${id}`
        }

        if ((general !== undefined) && (general !== '')) {
          urlRequestData = 'estudoMercado/analiseGeral'
        }

        api.get(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setUrlMarketStudy', response.data.data.casafariUrl)
                resolve(true)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
            }
          })
      })
    },

    setParamsUrl({ commit }, payload) {
      commit('setParamsUrl', payload)
    },
  },
}

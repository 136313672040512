export default {
  namespaced: true,
  state: {
    isNative: false,
    infoDevice: {},
    idDevice: null,
    localeDevice: null,
    orientationDevice: null,
  },
  getters: {
    isNative: state => state.isNative,
    infoDevice: state => state.infoDevice,
    idDevice: state => state.idDevice,
    localeDevice: state => state.localeDevice,
    orientationDevice: state => state.orientationDevice,
  },
  mutations: {
    setIsNativeApp(state, val) {
      state.isNative = val
    },
    setInfoDevice(state, val) {
      state.infoDevice = val
    },
    setDeviceID(state, val) {
      state.idDevice = val
    },
    setDeviceLocale(state, val) {
      state.localeDevice = val
    },
    setOrientationDevice(state, val) {
      state.orientationDevice = val
    },
  },
  actions: {},
}

<template>
  <component
    :is="componentScroll"
    ref="vuePerfectScrollbarComponentCustom"
    class="email-user-list scroll-area overflow-auto"
    v-bind="componentScrollSettings"
  >
    <slot />
  </component>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    settings: {
      required: false,
      default: () => {},
      type: Object,
    },
  },
  computed: {
    componentScroll() {
      if (this.$store.state.capacitor.isNative === false) {
        return VuePerfectScrollbar
      }

      return 'div'
    },

    componentScrollSettings() {
      if (this.$store.state.capacitor.isNative === false) {
        return { settings: this.settings }
      }

      return ''
    },
  },
}
</script>

const routeListingsNew = () => import(/* webpackChunkName: "listings-new" */ '@/views/modules/listings/form/index.vue')
const routeListingsListview = () => import(/* webpackChunkName: "listings-listview" */ '@/views/modules/listings/listview/index.vue')
const routeListingsMapa = () => import(/* webpackChunkName: "listings-listmap" */ '@/views/modules/listings/map/index.vue')
const routeUsersShareListview = () => import(/* webpackChunkName: "users-listview" */ '@/views/modules/listings/share/listviewUsersShare.vue')
const routeListingsMarketStudy = () => import(/* webpackChunkName: "listings-market" */ '@/views/modules/listings/marketStudy/index.vue')

export default [
  // {
  //   path: '/listings/new',
  //   name: 'listings-new',
  //   component: routeListingsNew,
  //   meta: {
  //     resource: 'listings',
  //     action: 'new',
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     breadcrumbNavbar: [
  //       {
  //         text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
  //         active: true,
  //       },
  //       {
  //         text: 'modules.listings.Nova angariação', /* <i18n path="modules.listings.Nova angariação"></i18n> */
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/listings/pre/new',
    name: 'listings-pre-new',
    component: routeListingsNew,
    meta: {
      resource: 'listings',
      action: 'new_pre',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Nova pré-angariação', /* <i18n path="modules.listings.Nova pré-angariação"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/openDraft/:id',
    name: 'listings-open-draft',
    component: routeListingsNew,
    meta: {
      resource: 'listings',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Nova angariação', /* <i18n path="modules.listings.Nova angariação"></i18n> */
          active: true,
        },
      ],
    },
    props: { draft: true },
  },
  {
    path: '/listings/viewDetail/:id',
    name: 'listings-view-detail',
    component: routeListingsNew,
    meta: {
      resource: 'listings',
      action: 'view',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
        },
      ],
    },
    props: { viewDetail: true },
  },
  {
    path: '/listings/viewDetail/:id/:status',
    name: 'listings-view-detail-pending',
    component: routeListingsNew,
    meta: {
      resource: 'listings',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
        },
      ],
    },
    props: { viewDetail: true },
  },
  {
    path: '/listings/edit/:id',
    name: 'listings-edit',
    component: routeListingsNew,
    meta: {
      resource: 'listings',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
        },
      ],
    },
  },
  {
    path: '/listings/market/study/:idImovel',
    name: 'listings-market-study',
    component: routeListingsMarketStudy,
    meta: {
      resource: 'listings',
      action: 'list',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
        },
        {
          text: 'modules.listings.Análise e Estudo de Mercado', /* <i18n path="modules.listings.Análise e Estudo de Mercado"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/list',
    name: 'listings-listview-list',
    component: routeListingsListview,
    meta: {
      resource: 'listings',
      action: 'list_my',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'static.menuListingName', /* <i18n path="modules.listings.My"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/pendentes',
    name: 'listings-listview-pendentes',
    component: routeListingsListview,
    meta: {
      resource: 'listings',
      action: 'manager',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Pendentes', /* <i18n path="modules.listings.Pendentes"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/myHUBLocation',
    name: 'listings-listview-myHUBLocation',
    component: routeListingsListview,
    meta: {
      resource: 'listings_morada',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Do meu HUB', /* <i18n path="modules.listings.Do meu HUB"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/myHUB',
    name: 'listings-listview-myHUB',
    component: routeListingsListview,
    meta: {
      resource: 'listings_hub',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: ' email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'static.menuListingNameHUB', /* <i18n path="modules.listings.HUB"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/rede',
    name: 'listings-listview-rede',
    component: routeListingsListview,
    meta: {
      resource: 'listings_rede',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Da rede', /* <i18n path="modules.listings.Da rede"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/externas',
    name: 'listings-listview-externas',
    component: routeListingsListview,
    meta: {
      resource: 'listings_externa',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Externas', /* <i18n path="modules.listings.Externas"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/favorites',
    name: 'listings-favorites',
    component: routeListingsListview,
    meta: {
      resource: 'listings_favoritos',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Favoritos', /* <i18n path="modules.listings.Favoritos"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/full-maps-view',
    name: 'listings-full-maps-view',
    component: routeListingsMapa,
    meta: {
      resource: 'listings_mapa',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application email-application-fullwidth',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Mapa', /* <i18n path="modules.listings.Mapa"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/myShares',
    name: 'listings-myShares',
    component: routeListingsListview,
    meta: {
      resource: 'listings_shares_my',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Minhas', /* <i18n path="modules.listings.Minhas"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/shares',
    name: 'listings-shares',
    component: routeListingsListview,
    meta: {
      resource: 'listings_my_shares',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.As minhas Angariações', /* <i18n path="modules.listings.As minhas Angariações"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/sharedByConsultant',
    name: 'listings-shared-byConsultant',
    component: routeUsersShareListview,
    meta: {
      resource: 'listings_shares_agent',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'ecommerce-application email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
          active: true,
        },
        {
          text: 'static.menuShareAgentsNameHUB', /* <i18n path="modules.listings.Consultores"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/sharedByConsultantHUB',
    name: 'listings-shared-byConsultantHUB',
    component: routeUsersShareListview,
    meta: {
      resource: 'listings_shares_agent_hub',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'ecommerce-application email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Consultores do meu HUB', /* <i18n path="modules.listings.Consultores do meu HUB"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/sharedListings',
    name: 'listings-shared',
    component: routeListingsListview,
    meta: {
      resource: 'listings_shares_geral',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
          active: true,
        },
        {
          text: 'static.menuShareListingNameHUB', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/listings/sharedListingsHUB',
    name: 'listings-shared-HUB',
    component: routeListingsListview,
    meta: {
      resource: 'listings_shares_hub',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
          active: true,
        },
        {
          text: 'modules.listings.Angariações do meu HUB', /* <i18n path="modules.listings.Angariações do meu HUB"></i18n> */
          active: true,
        },
      ],
    },
  },
]

<template>

  <b-overlay
    show
    no-wrap
    class="loading-app"
    :z-index="loading.zIndex"
    :opacity="loading.opacity"
    :fixed="true"
  >
    <template #overlay>
      <div class="text-center">
        <feather-icon
          :icon="loading.icon"
          size="24"
          :class="loading.classExtra"
        />
        <b-card-text
          id="cancel-label"
          class="mt-1"
          :class="loading.classExtra"
        >
          {{ loading.txt }}
        </b-card-text>
      </div>
    </template>
  </b-overlay>

</template>

<script>
import { BOverlay, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCardText,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      configDefault: {
        txt: this.$t('Aguarde...'),
        zIndex: 9999,
        opacity: 0.95,
        icon: 'ClockIcon',
        classExtra: '',
      },
      loading: {},
    }
  },
  beforeMount() {
    this.loading = { ...this.configDefault, ...this.config }
  },
}
</script>

import { format, parseISO } from 'date-fns'
import i18n from '@/libs/i18n'
import btoa from 'btoa'

export const getWeekDay = (day, bReturnPlurals = false, bReturnPluralsString = false) => {
  if (bReturnPlurals) {
    if (bReturnPluralsString) {
      if (Number(day) === 0) return i18n.t('Todos os domingos')
      if (Number(day) === 1) return i18n.t('Todas as segundas')
      if (Number(day) === 2) return i18n.t('Todas as terças')
      if (Number(day) === 3) return i18n.t('Todas as quartas')
      if (Number(day) === 4) return i18n.t('Todas as quintas')
      if (Number(day) === 5) return i18n.t('Todas as sextas')
      if (Number(day) === 6) return i18n.t('Todos os sábados')
    } else {
      if (Number(day) === 0) return i18n.t('Domingos')
      if (Number(day) === 1) return i18n.t('Segundas')
      if (Number(day) === 2) return i18n.t('Terças')
      if (Number(day) === 3) return i18n.t('Quartas')
      if (Number(day) === 4) return i18n.t('Quintas')
      if (Number(day) === 5) return i18n.t('Sextas')
      if (Number(day) === 6) return i18n.t('Sábados')
    }
  } else {
    if (Number(day) === 0) return i18n.t('Domingo')
    if (Number(day) === 1) return i18n.t('Segunda')
    if (Number(day) === 2) return i18n.t('Terça')
    if (Number(day) === 3) return i18n.t('Quarta')
    if (Number(day) === 4) return i18n.t('Quinta')
    if (Number(day) === 5) return i18n.t('Sexta')
    if (Number(day) === 6) return i18n.t('Sábado')
  }

  return ''
}

export const getMonthToMonthFirstDayMessage = row => {
  let txt = i18n.t('Primeira(o)')
  txt += ` ${getWeekDay(row.sw030s16)}`

  if (Number(row.sw030s33) === 1) {
    txt += ` ${i18n.t('de mês a mês')}`
  } else {
    txt += ` ${i18n.t('de {numero} em {numero} meses', { numero: row.sw030s33 })}`
  }

  return txt
}

export const getMonthToMonthDayOfMonthMessage = row => {
  let txt = ''
  if (Number(row.sw030s33) === 1) {
    txt += i18n.t('De mês a mês no dia')
  } else {
    txt += i18n.t('de {numero} em {numero} meses no dia', { numero: row.sw030s33 })
  }

  txt += ` ${format(parseISO(row.sw030s04), 'dd')}`

  return txt
}

export const renderInfoExtraDataRow = (state, row) => {
  let txtDate = ''
  let txtDateExtra = ''
  let dataSeg = ''
  let today = ''
  let classElm = ''
  let strAbbrState = ''
  const actionsSeg = []

  if ((row !== null) && (row !== '') && (row !== undefined)) {
    if ('sw030s04' in row) {
      if ((row.sw030s04 !== null) && (row.sw030s04 !== undefined)) {
        let dateTime = row.sw030s04.replace('00:00:00', '')
        dateTime = dateTime.replace(':00', '')
        const aDate = dateTime.split(' ')
        const strDate = aDate[1].split(':')

        today = format(new Date(), 'yyyy-MM-dd')
        dataSeg = format(parseISO(dateTime), 'yyyy-MM-dd')

        if ((Number(row.sw030s14) === 0) || (Number(row.sw030s23) === 1)) {
          let bHasHour = false
          if (aDate[0]) {
            txtDate += aDate[0]
          }

          if (strDate[0]) {
            txtDateExtra += ` ${strDate[0]}h`
            bHasHour = true
          }

          if (strDate[1] && (strDate[1] !== '00')) {
            txtDateExtra += `${strDate[1]}`
          } else if (!bHasHour) {
            txtDateExtra += i18n.t('Todo o dia')
          }
        } else {
          let res = ''
          if ((typeof state.dataRotinaFicticia !== 'undefined') && (state.dataRotinaFicticia !== null) && (state.dataRotinaFicticia !== '')) {
            res = state.idRotinaFicticia.split('#FICTICIO#')
          }

          // Descrição do titulo da rotina
          let txtRotina = `${i18n.t('Rotina')}: `
          if (Number(row.sw030s15) === 1) {
            txtRotina += getWeekDay(row.sw030s16)
          } else if (Number(row.sw030s15) === 2) {
            txtRotina += getWeekDay(row.sw030s16)
            txtRotina += ` ${i18n.t('de 15 em 15 dias')}`
          } else if (Number(row.sw030s15) === 3) {
            txtRotina += i18n.t('Primeira(o)')
            txtRotina += ` ${getWeekDay(row.sw030s16)}`
            txtRotina += ` ${i18n.t('do mês')}`
          } else if (Number(row.sw030s15) === 4) {
            txtRotina += i18n.t('Todos os meses no dia')
            txtRotina += ` ${format(parseISO(row.sw030s04), 'dd')}`
          } else if ((Number(row.sw030s15) === 5) && (Number(row.sw030s34) === 1)) {
            txtRotina += getMonthToMonthFirstDayMessage(row)
          } else if ((Number(row.sw030s15) === 5) && (Number(row.sw030s34) === 2)) {
            txtRotina += getMonthToMonthDayOfMonthMessage(row)
          }

          if (Number(row.sw030s18) === 1) {
            txtRotina += ` ${i18n.t('Todo o dia')}`
          } else {
            txtRotina += ` ${i18n.t('às {hora}', { hora: format(parseISO(row.sw030s04), 'HH:mm') })}`
          }

          if (res !== '') {
            if (res[0] === btoa(row.sw030s01)) {
              const sData = txtRotina.split('-')
              if (typeof sData[3] === 'undefined') {
                txtDate = `${state.dataRotinaFicticia.substring(0, 10)} - ${txtRotina}`
              } else {
                txtDate = `${state.dataRotinaFicticia.substring(0, 10)} - ${sData[3]}`
              }
            } else {
              txtDate = txtRotina || ''
            }
          } else {
            txtDate = txtRotina || ''
          }
        }

        // Info Extra
        if ((aDate[0] === today) && (row.sw030s09 !== 1) && (row.sw030s10 !== 1) && (row.sw030s09 !== 2)) {
          classElm = 'now'
          strAbbrState = i18n.t('Hoje')
        } else {
          classElm = 'next'
          strAbbrState = i18n.t('Próximo')
        }

        if (Number(row.sw030s10) === 1) {
          classElm = 'delete'
        }

        if ((aDate[0] < today) && (Number(row.sw030s09) !== 1) && (Number(row.sw030s10) !== 1) && (Number(row.sw030s09) !== 2)) {
          classElm = 'dateOld'
          strAbbrState = i18n.t('Em atraso')
        } else if (Number(row.sw030s09) === 1) {
          classElm = 'regEfetuado'
          strAbbrState = i18n.t('Efetuado')
        } else if (Number(row.sw030s09) === 2) {
          classElm = 'regNaoEfetuado'
          strAbbrState = i18n.t('Não efetuado')
        }

        if (Number(row.sw030s10) !== 1) {
        // Botoes de acao
          if (state.canEdit === true) {
          // Efetuado
            if (Number(row.sw030s14) === 1) {
              actionsSeg.push({
                action: 'checkStateRotinaReg',
                txt: i18n.t('Efetuado'),
                active: (Number(row.sw030s09) === 1),
                date: row.sw030s11,
                main: true,
              })
            } else if (Number(row.sw030s13) === 7) {
              actionsSeg.push({
                action: 'checkStateCampanhaReg',
                txt: i18n.t('Efetuado'),
                active: (Number(row.sw030s09) === 1),
                date: row.sw030s11,
                main: true,
              })
            } else {
              actionsSeg.push({
                action: 'checkStateReg',
                txt: i18n.t('Efetuado'),
                active: (Number(row.sw030s09) === 1),
                date: row.sw030s11,
                main: true,
              })
            }

            // Nao efetuado
            if (Number(row.sw030s14) === 1) {
              actionsSeg.push({
                action: 'uncheckStateRotinaReg',
                txt: i18n.t('Não efetuado'),
                active: (Number(row.sw030s09) === 2),
                date: row.sw030s11,
                main: true,
              })
            } else if (Number(row.sw030s13) === 7) {
              actionsSeg.push({
                action: 'uncheckStateCampanhaReg',
                txt: i18n.t('Não efetuado'),
                active: (Number(row.sw030s09) === 2),
                date: row.sw030s11,
                main: true,
              })
            } else {
              actionsSeg.push({
                action: 'uncheckStateReg',
                txt: i18n.t('Não efetuado'),
                active: (Number(row.sw030s09) === 2),
                date: row.sw030s11,
                main: true,
              })
            }

            // Outras acoes
            if ((typeof row.sw025s80 !== 'undefined' && row.sw025s80 === 7) || (typeof row.bAlteraResp !== 'undefined' && row.bAlteraResp === true)) {
              actionsSeg.push({
                action: 'editseguimentoUserResp',
                txt: i18n.t('Alterar responsável'),
                main: false,
              })
            }

            if (dataSeg >= today) {
              actionsSeg.push({
                action: 'editseguimentodata',
                txt: i18n.t('Alterar data'),
                main: false,
              })
            }

            actionsSeg.push({
              action: 'editseguimento',
              txt: i18n.t('Editar'),
              main: false,
            })

            if (Number(row.sw030s14) === 1) {
              actionsSeg.push({
                action: 'changeStatusDatesRotina',
                txt: i18n.t('Alterar estado dos seguimentos'),
                main: false,
              })
            }

            if ((Number(row.sw030s14) === 1) && (Number(row.sw030s23) !== 1)) {
              actionsSeg.push({
                action: 'concluirRotina',
                txt: i18n.t('Concluir rotina'),
                main: false,
              })
            }

            if (Number(row.sw030s13) === 7) {
            // Acoes nao vao ser neste momento implementadas
              actionsSeg.push({
                action: 'showScript',
                txt: i18n.t('Ver script / Toque'),
                main: false,
              })

              actionsSeg.push({
                action: 'showCampanha',
                txt: i18n.t('Ver Campanha'),
                main: false,
              })
            }
          }

          // Eliminar
          if (state.canDelete === true) {
            if (Number(row.sw030s14) === 1) {
              actionsSeg.push({
                action: 'deleteRotinaReg',
                txt: i18n.t('Eliminar'),
                main: false,
              })
            } else if (Number(row.sw030s13) !== 7) {
              actionsSeg.push({
                action: 'deleteReg',
                txt: i18n.t('Eliminar'),
                main: false,
              })
            }
          }
        }
      }
    }
  }

  return {
    txtDate,
    txtDateExtra,
    dataSeg,
    today,
    classElm,
    strAbbrState,
    actionsSeg,
  }
}

export const _ = null

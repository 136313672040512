const routeDashboard = () => import(/* webpackChunkName: "notification-list" */ '@/views/modules/dashboard/index.vue')
const routeNotificationList = () => import(/* webpackChunkName: "notification-list" */ '@/views/modules/notification/index.vue')

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: routeDashboard,
    meta: {
      resource: 'Geral',
      action: 'manager',
      contentRenderer: 'sidebar-left',
      contentClass: 'page-iframe email-application-fullwidth email-application',
    },
  },
  {
    path: '/notification/list',
    name: 'notification-list',
    component: routeNotificationList,
    meta: {
      resource: 'Geral',
      action: 'manager',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.Notificações', /* <i18n path="modules.Notificações"></i18n> */
          active: true,
        },
      ],
    },
  },
]

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Listagem
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(25),
  perPageDashboard: ref(5),
  totalRows: ref(0),
  currentPage: ref(1),
  searchTxt: ref(''),
  totalFieldsFilter: ref(0),
  showListviewFilter: ref(false),
  txtSearchPlaceholder: '',
  txtLoading: i18n.t('A carregar registos ...'),
  txtDefault: i18n.t('Não existe nenhum seguimento'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  fieldsOrder: [],
  statusFilter: [{
    tab: 'proximos',
    txt: i18n.t('Por fazer'),
  },
  {
    tab: 'todos',
    txt: i18n.t('Todos'),
  },
  {
    tab: 'historico',
    txt: i18n.t('Histórico'),
  },
  {
    tab: 'lixo',
    txt: i18n.t('Lixo'),
  }],
  filterByStatus: ref(''),
  filterByType: ref(''),
  canDelete: false,
  canEdit: false,

  idRotinaFicticia: '',
  dataRotinaFicticia: '',

  // Formulario
  usersCreateSeguimento: ref([]),
  tiposSeguimentos: ref([]),
  diaDaSemana: ref([]),
  diaSemana: ref([]),
  typeWeekMonth: ref([]),
  typeSend: ref([]),
  optionsNovoSeguimento: ref([]),
  datasRoutina: ref([]),
  optionsStatusDate: [
    {
      id: 1,
      txt: i18n.t('Efetuado'),
    },
    {
      id: 2,
      txt: i18n.t('Não efetuado'),
    },
  ],

  statusFilterDashboard: ref([]),
})

export default defaultState

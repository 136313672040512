const routeSalesListview = () => import(/* webpackChunkName: "sales-processosvenda-listview" */ '@/views/modules/sales/listview/index.vue')

export default [
  {
    path: '/sales/salesprocess/list',
    name: 'sales-listview-processos-venda',
    component: routeSalesListview,
    meta: {
      resource: 'sales_listview_processos',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.sales.Vendas', /* <i18n path="modules.sales.Vendas"></i18n> */
          active: true,
        },
        {
          text: 'modules.sales.Processos de venda', /* <i18n path="modules.sales.Processos de venda"></i18n> */
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales/salesprocess/lease',
    name: 'sales-listview-processos-arrendamento',
    component: routeSalesListview,
    meta: {
      resource: 'sales_listview_processos',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'modules.sales.Vendas', /* <i18n path="modules.sales.Vendas"></i18n> */
          active: true,
        },
        {
          text: 'modules.sales.Processos de arrendamento', /* <i18n path="modules.sales.Processos de arrendamento"></i18n> */
          active: true,
        },
      ],
    },
  },
]

import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {

    async getTotalLeadsImport() {
      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()

        if ((localStorage.getItem('sw453_skip_leads') !== null) && (localStorage.getItem('sw453_skip_leads') !== '')) {
          const stateCustom = JSON.parse(localStorage.getItem('sw453_skip_leads'))
          stateCustom.forEach(field => {
            formData.append('sw453_skip_leads[]', field)
          })
        }

        api.post(`${apiConfig.url_base_api}leads/importacao/getTotalLeadsImport`, formData)
          .then(response => {
            try {
              if (response.data !== undefined) {
                resolve(response.data)
              } else {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação')))
            }
          })
      })

      return request
    },
  },
}

import { format, parseISO } from 'date-fns'
import i18n from '@/libs/i18n'

export const getWeekDay = (day, bReturnPlurals = false, bReturnPluralsString = false) => {
  if (bReturnPlurals) {
    if (bReturnPluralsString) {
      if (Number(day) === 0) return i18n.t('Todos os domingos')
      if (Number(day) === 1) return i18n.t('Todas as segundas')
      if (Number(day) === 2) return i18n.t('Todas as terças')
      if (Number(day) === 3) return i18n.t('Todas as quartas')
      if (Number(day) === 4) return i18n.t('Todas as quintas')
      if (Number(day) === 5) return i18n.t('Todas as sextas')
      if (Number(day) === 6) return i18n.t('Todos os sábados')
    } else {
      if (Number(day) === 0) return i18n.t('Domingos')
      if (Number(day) === 1) return i18n.t('Segundas')
      if (Number(day) === 2) return i18n.t('Terças')
      if (Number(day) === 3) return i18n.t('Quartas')
      if (Number(day) === 4) return i18n.t('Quintas')
      if (Number(day) === 5) return i18n.t('Sextas')
      if (Number(day) === 6) return i18n.t('Sábados')
    }
  } else {
    if (Number(day) === 0) return i18n.t('Domingo')
    if (Number(day) === 1) return i18n.t('Segunda')
    if (Number(day) === 2) return i18n.t('Terça')
    if (Number(day) === 3) return i18n.t('Quarta')
    if (Number(day) === 4) return i18n.t('Quinta')
    if (Number(day) === 5) return i18n.t('Sexta')
    if (Number(day) === 6) return i18n.t('Sábado')
  }

  return ''
}

export const getMonthToMonthFirstDayMessage = row => {
  let txt = i18n.t('Primeira(o)')
  txt += ` ${getWeekDay(row.sw030s16)}`

  if (Number(row.sw030s33) === 1) {
    txt += ` ${i18n.t('de mês a mês')}`
  } else {
    txt += ` ${i18n.t('de {numero} em {numero} meses', { numero: row.sw030s33 })}`
  }

  return txt
}

export const getMonthToMonthDayOfMonthMessage = row => {
  let txt = ''
  if (Number(row.sw030s33) === 1) {
    txt += i18n.t('De mês a mês no dia')
  } else {
    txt += i18n.t('de {numero} em {numero} meses no dia', { numero: row.sw030s33 })
  }

  txt += ` ${format(parseISO(row.sw030s04), 'dd')}`

  return txt
}

export const renderInfoExtraDataRow = (state, row) => {
  let txtDate = ''
  let txtDateExtra = ''
  let dataSeg = ''
  let today = ''
  let classElm = ''
  let strAbbrState = ''
  const actionsSeg = []

  if ((row !== null) && (row !== '') && (row !== undefined)) {
    if ('sw030s04' in row) {
      if ((row.sw030s04 !== null) && (row.sw030s04 !== undefined)) {
        let dateTime = row.sw030s04.replace('00:00:00', '')
        dateTime = dateTime.replace(':00', '')
        const aDate = dateTime.split(' ')
        const strDate = aDate[1].split(':')

        today = format(new Date(), 'yyyy-MM-dd')
        dataSeg = format(parseISO(dateTime), 'yyyy-MM-dd')

        if ((Number(row.sw030s14) === 0) || (Number(row.sw030s23) === 1)) {
          let bHasHour = false
          if (aDate[0]) {
            txtDate += aDate[0]
          }

          if (strDate[0]) {
            txtDateExtra += ` ${strDate[0]}h`
            bHasHour = true
          }

          if (strDate[1] && (strDate[1] !== '00')) {
            txtDateExtra += `${strDate[1]}`
          } else if (!bHasHour) {
            txtDateExtra += i18n.t('Todo o dia')
          }
        }

        // Info Extra
        if ((aDate[0] === today) && (row.sw030s09 !== 1) && (row.sw030s10 !== 1) && (row.sw030s09 !== 2)) {
          classElm = 'now'
          strAbbrState = i18n.t('Hoje')
        } else {
          classElm = 'next'
          strAbbrState = i18n.t('Próximo')
        }

        if (Number(row.sw030s10) === 1) {
          classElm = 'delete'
        }

        if ((aDate[0] < today) && (Number(row.sw030s09) !== 1) && (Number(row.sw030s10) !== 1) && (Number(row.sw030s09) !== 2)) {
          classElm = 'dateOld'
          strAbbrState = i18n.t('Em atraso')
        } else if (Number(row.sw030s09) === 1) {
          classElm = 'regEfetuado'
          strAbbrState = i18n.t('Efetuado')
        } else if (Number(row.sw030s09) === 2) {
          classElm = 'regNaoEfetuado'
          strAbbrState = i18n.t('Não efetuado')
        }

        if (Number(row.sw030s10) !== 1) {
        // Botoes de acao
          if (dataSeg >= today) {
            actionsSeg.push({
              action: 'editseguimentodata',
              txt: i18n.t('Alterar data'),
              main: false,
            })
          }

          actionsSeg.push({
            action: 'editseguimento',
            txt: i18n.t('Editar'),
            main: false,
          })

        //   actionsSeg.push({
        //     action: 'deleteReg',
        //     txt: i18n.t('Eliminar'),
        //     main: false,
        //   })
        }
      }
    }
  }

  return {
    txtDate,
    txtDateExtra,
    dataSeg,
    today,
    classElm,
    strAbbrState,
    actionsSeg,
  }
}

export const _ = null
